import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { request } from '@advitam/api';
import type { FlightIndexJSON } from '@advitam/api/models/Flight/FlightIndex';
import { assert } from '@advitam/support';

import type { AppStateSubset } from '../../../../../../slice';
import { PLANE } from './constants';
import { makeSelectRepatriationStep } from './selectors';

export const fetchFlights = createAsyncThunk(
  `${PLANE}/FETCH_FLIGHTS`,
  async (_: void, { getState }) => {
    const state = getState() as AppStateSubset;
    const repatriationStep = makeSelectRepatriationStep()(state);
    assert(repatriationStep.destination.country_id !== null);

    const filters = {
      per_page: 1000,
      arrival_airport: {
        country_id: repatriationStep.destination.country_id,
      },
    };

    let page = 0;
    let totalPages = 1;
    const result: FlightIndexJSON[] = [];

    while (page < totalPages) {
      page += 1;
      // eslint-disable-next-line no-await-in-loop
      const response = await request(
        Api.V1.Flights.index({ ...filters, page }),
      );
      result.push(...response.body);

      totalPages = parseInt(response.headers.get('X-Ad-TotalPages') ?? '1', 10);
    }

    return result;
  },
);
