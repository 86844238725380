import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { assert } from '@advitam/support';
import { makeSelectWorshipTypes } from 'slices/data';
import {
  makeSelectIsManualMode,
  makeSelectWishes,
  makeSelectWish,
  makeSelectGraveyardBasicInfos,
  makeSelectSteps,
  makeSelectDeal,
} from 'containers/Deal/selectors.js';
import {
  getGraveyardConcessionTypes as getGraveyardConcessionTypesDispatch,
  getGraveyardConcessions as getGraveyardConcessionsDispatch,
  getGraveyardBasicInfos as getGraveyardBasicInfosDispatch,
} from 'containers/Deal/actions.js';
import { DEAL_TYPE_FUNERAL } from 'containers/Deal/constants';
import { LegacyStep } from 'models/Deal/Step';
import { makeSelectUser } from 'slices/auth';

import { makeSelectDisplayStepsFactory } from '../../selectors.typed';
import { setDisplayStepsFactory } from '../../slice';
import {
  STEP_CASKETING,
  STEP_TBC,
  STEP_INTERMENT,
  STEP_URN_SEALING,
} from '../constants.js';
import { updateDeal, updateWishes } from '../actions.js';
import {
  CremationIcon,
  RepatriationIcon,
  CasketingIcon,
  CustomIcon,
  LeavesIcon,
} from '../Images/index.js';
import messages from '../messages.js';
import { makeSelectFuneral } from '../selectors.js';

import {
  getConcessionPrices as getConcessionPricesDispatch,
  openDeleteStepDialog,
  setAshesDestination as setAshesDestinationDispatch,
  getCrematorium as getCrematoriumDispatch,
  getCeremonies as getCeremoniesDispatch,
  getFuneralParlorStayTypes as getFuneralParlorStayTypesDispatch,
  setFuneralParlorStayTypes as setFuneralParlorStayTypesDispatch,
  updateDealFromSteps,
} from './actions.js';
import {
  makeSelectStep,
  makeSelectConcessionPrices,
  makeSelectCrematorium,
  makeSelectDefunctAge,
  makeSelectCeremonies,
  makeSelectConcessionTypes,
  makeSelectFuneralParlorStayTypes,
  makeSelectCoffin,
} from './selectors.js';
import {
  makeSelectStepServices,
  makeSelectService,
} from './Services/selectors.js';
import {
  addService as addServiceDispatch,
  removeService as removeServiceAction,
  removeTriggerPingService,
  updateService as updateServiceDispatch,
} from './Services/actions.js';
import Step from './Step.js';
import AddStep from './AddStep.js';
import getSkeleton from './skeletons.js';
import style from './Steps.module.scss';

export default function Steps(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const deal = useSelector(makeSelectDeal());
  const steps = useSelector(makeSelectSteps()) as LegacyStep[];
  const worshipTypes = useSelector(makeSelectWorshipTypes());
  const concessionPrices = useSelector(makeSelectConcessionPrices());
  const getStep = useSelector(makeSelectStep());
  const getServices = useSelector(makeSelectStepServices());
  const findService = useSelector(makeSelectService());
  const findWish = useSelector(makeSelectWish());
  const wishes = useSelector(makeSelectWishes());
  const crematorium = useSelector(makeSelectCrematorium());
  const ceremonies = useSelector(makeSelectCeremonies());
  const defunctAge = useSelector(makeSelectDefunctAge());
  const ConcessionTypes = useSelector(makeSelectConcessionTypes());
  const graveyardBasicInfos = useSelector(makeSelectGraveyardBasicInfos());
  const funeralParlorStayTypes = useSelector(
    makeSelectFuneralParlorStayTypes(),
  );
  const funeral = useSelector(makeSelectFuneral());
  const coffin = useSelector(makeSelectCoffin());
  const isManualMode = useSelector(makeSelectIsManualMode()) as boolean;
  const user = useSelector(makeSelectUser());
  assert(user !== null);
  const displayStepsFactory = useSelector(makeSelectDisplayStepsFactory());

  const getConcessionPrices = (id: number): void => {
    dispatch(getConcessionPricesDispatch(id));
  };
  const getCrematorium = (id: number): void => {
    dispatch(getCrematoriumDispatch(id));
  };
  const getCeremonies = (id: number, params: unknown): void => {
    dispatch(getCeremoniesDispatch(id, params));
  };
  const getGraveyardConcessionTypes = (id: number): void => {
    dispatch(getGraveyardConcessionTypesDispatch(id));
  };
  const getGraveyardConcessions = (id: number): void => {
    dispatch(getGraveyardConcessionsDispatch(id));
  };
  const getGraveyardBasicInfos = (id: number): void => {
    dispatch(getGraveyardBasicInfosDispatch(id));
  };
  const getFuneralParlorStayTypes = (id: number): void => {
    dispatch(getFuneralParlorStayTypesDispatch(id));
  };
  const setFuneralParlorStayTypes = (stayTypes: unknown): void => {
    dispatch(setFuneralParlorStayTypesDispatch(stayTypes));
  };

  const setAshesDestination = (destinationType: unknown): void => {
    setAshesDestinationDispatch(dispatch, steps, destinationType, funeral);
  };
  const handleUpdateDeal = (update: unknown): void => {
    dispatch(updateDeal(update, funeral));
  };
  const updateWish = (wishType: unknown, value: unknown): void => {
    dispatch(updateWishes(wishType, value, funeral, DEAL_TYPE_FUNERAL));
  };
  const openDeleteStepDialogProps = (
    stepId: number,
    stepEventType: unknown,
  ): void => {
    dispatch(
      openDeleteStepDialog(dispatch, stepId, stepEventType, steps, funeral),
    );
  };
  const removeService = (service: unknown): void => {
    dispatch(removeServiceAction(service, funeral, DEAL_TYPE_FUNERAL));
    dispatch(removeTriggerPingService(service));
  };
  const addService = (service: unknown): void => {
    dispatch(addServiceDispatch(service, funeral, DEAL_TYPE_FUNERAL));
  };
  const updateService = (service: unknown): void => {
    dispatch(updateServiceDispatch(service, funeral, DEAL_TYPE_FUNERAL));
  };
  const updateStep = (update: unknown, stepToUpdate: unknown): void => {
    dispatch(updateDealFromSteps(update, stepToUpdate, steps, deal));
  };

  const createSteps = (skeletonType: unknown): void => {
    dispatch(setDisplayStepsFactory(false));
    handleUpdateDeal({ steps: getSkeleton(skeletonType) });
  };

  const confirmDeleteStep = (
    stepId: number,
    stepEventType: string,
    stepsToRemove: LegacyStep[],
  ): void => {
    openDeleteStepDialogProps(stepId, stepEventType);
    const stepCasketingIndex = stepsToRemove.findIndex(
      step => (step.eventType as string) === STEP_CASKETING,
    );
    const stepCasketing = stepsToRemove[stepCasketingIndex];
    if (
      stepEventType === STEP_TBC &&
      stepCasketing &&
      (stepCasketing as LegacyStep & { tribunal_authorization_date: unknown })
        .tribunal_authorization_date
    ) {
      const newSteps = [...stepsToRemove];
      newSteps[stepCasketingIndex] = {
        ...stepsToRemove[stepCasketingIndex],
        tribunal_authorization_date: null,
      } as LegacyStep;
      handleUpdateDeal({ steps: newSteps });
    }
  };

  const skeletonButtons = (): JSX.Element => (
    <div>
      <button
        type="button"
        className={style.skeleton_button}
        onClick={() => createSteps('interment')}
      >
        <div
          data-tooltip={intl.formatMessage({
            ...messages.interment,
          })}
          data-tooltip-location="right"
        >
          <LeavesIcon className="steps__skeletonIcon" />
        </div>
      </button>
      <button
        type="button"
        className={style.skeleton_button}
        onClick={() => createSteps('cremation')}
      >
        <div
          data-tooltip={intl.formatMessage({
            ...messages.cremation,
          })}
          data-tooltip-location="top"
        >
          <CremationIcon className="steps__skeletonIcon" />
        </div>
      </button>
      <button
        type="button"
        className={style.skeleton_button}
        onClick={() => createSteps('repatriation')}
      >
        <div
          data-tooltip={intl.formatMessage({
            ...messages.repatriation,
          })}
          data-tooltip-location="top"
        >
          <RepatriationIcon className="steps__skeletonIcon" />
        </div>
      </button>
      <button
        type="button"
        className={style.skeleton_button}
        onClick={() => createSteps('casketing')}
      >
        <div
          data-tooltip={intl.formatMessage({
            ...messages.casketing,
          })}
          data-tooltip-location="top"
        >
          <CasketingIcon className="steps__skeletonIcon" />
        </div>
      </button>
      <button
        type="button"
        className={style.skeleton_button}
        onClick={() => createSteps('custom')}
      >
        <div
          data-tooltip={intl.formatMessage({
            ...messages.custom,
          })}
          data-tooltip-location="top"
        >
          <CustomIcon className="steps__skeletonIcon" />
        </div>
      </button>
    </div>
  );

  if (displayStepsFactory) {
    return skeletonButtons();
  }

  const hasConcessionSelection = steps.find(
    (step: LegacyStep) =>
      (step.eventType as string) === STEP_INTERMENT ||
      (step.eventType as string) === STEP_URN_SEALING,
  );

  return (
    <div>
      {steps.map((step: LegacyStep) => (
        <Step
          key={`${step.id}${step.eventType}`}
          step={step}
          handleUpdateDeal={handleUpdateDeal}
          confirmDeleteStep={confirmDeleteStep}
          findService={findService(step.id)}
          removeService={removeService}
          addService={addService}
          updateWish={updateWish}
          updateStep={updateStep}
          findWish={findWish}
          steps={steps}
          isManualMode={isManualMode}
          userRole={user.role}
          updateService={updateService}
          getGraveyardBasicInfos={getGraveyardBasicInfos}
          graveyardBasicInfos={graveyardBasicInfos}
          deal={deal}
          worshipTypes={worshipTypes}
          concessionPrices={concessionPrices}
          getStep={getStep}
          getServices={getServices}
          wishes={wishes}
          crematorium={crematorium}
          ceremonies={ceremonies}
          defunctAge={defunctAge}
          ConcessionTypes={ConcessionTypes}
          funeralParlorStayTypes={funeralParlorStayTypes}
          funeral={funeral as object}
          coffin={coffin}
          user={user}
          getConcessionPrices={getConcessionPrices}
          getCrematorium={getCrematorium}
          getCeremonies={getCeremonies}
          getGraveyardConcessionTypes={getGraveyardConcessionTypes}
          getGraveyardConcessions={getGraveyardConcessions}
          getFuneralParlorStayTypes={getFuneralParlorStayTypes}
          setFuneralParlorStayTypes={setFuneralParlorStayTypes}
          setAshesDestination={setAshesDestination}
          openDeleteStepDialogProps={openDeleteStepDialogProps}
          isNewDeal={Boolean(deal?.uuid)}
        />
      ))}
      {!hasConcessionSelection && (
        <AddStep
          opened
          handleUpdateDeal={handleUpdateDeal}
          stepId={steps.length}
          funeral={funeral}
        />
      )}
    </div>
  );
}
