import { authenticate } from '../../lib/decorators'
import type { ApiRequestDescriptor } from '../../lib/request'
import { get, put } from '../../lib/methods'
import type { GradedDealReviewJSON } from '../../models/Deal/GradedReview'
import type { ReviewJSON } from '../../models/Deal/Review'

export interface ReviewUpdatePayload {
  funeral_advisor_grade: number
  funeral_advisor_commentary?: string
  ceremony_grade: number
  ceremony_commentary?: string
}

export const Reviews = {
  index: (dealUuid: string): ApiRequestDescriptor<GradedDealReviewJSON[]> =>
    authenticate(get(`/api/v1/deals/${dealUuid}/reviews`)),

  show: (uuid: string): ApiRequestDescriptor<ReviewJSON> => get(`/api/v1/deals/reviews/${uuid}`),

  update: (uuid: string, payload: ReviewUpdatePayload): ApiRequestDescriptor<void> =>
    put(`/api/v1/deals/reviews/${uuid}`, payload),
}
