import { createSelector, Selector } from 'reselect';

import { FLIGHT_FILTERS } from './constants';
import type { AppStateSubset, State } from './slice';
import { RatesFilters } from './types';

type FilterSelector<T> = Selector<AppStateSubset, T>;

function selectFilters(state: AppStateSubset): State {
  return state[FLIGHT_FILTERS];
}

export function makeSelectFilters(): FilterSelector<RatesFilters> {
  return createSelector(selectFilters, state => state.filters);
}
