import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  commentary: { id: 'app.containers.Flight.commentary' },
  general: { id: 'app.containers.Flight.general' },
  rates: { id: 'app.containers.Flight.rates' },

  informations: { id: 'app.containers.Flight.informations' },
  airline: { id: 'app.containers.Flight.airline' },
  departureAirport: { id: 'app.containers.Flight.departureAirport' },
  stopoverAirport: { id: 'app.containers.Flight.stopoverAirport' },
  arrivalAirport: { id: 'app.containers.Flight.arrivalAirport' },

  supplier: { id: 'app.containers.Flight.supplier' },
  activeSupplier: { id: 'app.containers.Flight.activeSupplier' },
  depositDays: { id: 'app.containers.Flight.depositDays' },
  depositDaysTooltip: { id: 'app.containers.Flight.depositDaysTooltip' },

  addRate: { id: 'app.containers.Flight.addRate' },
  maxWeight: { id: 'app.containers.Flight.maxWeight' },
  price: { id: 'app.containers.Flight.price' },
  actions: { id: 'app.containers.Flight.actions' },
  lastUpdate: { id: 'app.containers.Flight.lastUpdate' },

  deletionConfirmationText: {
    id: 'app.containers.Flight.deletionConfirmationText',
  },
  deletionConfirmationConfirm: {
    id: 'app.containers.Flight.deletionConfirmationConfirm',
  },
  deletionConfirmationCancel: {
    id: 'app.containers.Flight.deletionConfirmationCancel',
  },

  flight__base__restrict_dependent_destroy__has_many: {
    id:
      'app.containers.Flight.flight__base__restrict_dependent_destroy__has_many',
  },
});
