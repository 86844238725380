import {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  ReactElement,
  ReactNode,
  RefCallback,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Autosuggest, {
  BlurEvent,
  ChangeEvent as AutosuggestChangeEvent,
  RenderSuggestionsContainer,
  SuggestionSelectedEventData,
  SuggestionsFetchRequestedParams,
  RenderInputComponent,
} from 'react-autosuggest'
import { IMaskInput } from 'react-imask'
import { debounce } from 'throttle-debounce'

import { Objects } from '@advitam/support'

import { PageSpinner } from '../../../Spinner'
import ClearButton from '../parts/ClearButton'
import Label from '../parts/Label'
import uiStyle from '../UI.module.scss'
import style from './Autosuggest.module.scss'

const MINIMUM_CHAR_REQUEST = 3

interface AutocompleteProps<TSuggestion> {
  label?: ReactNode
  name?: string
  value?: TSuggestion
  tooltip?: ReactNode
  getDisplayValue: (data: TSuggestion) => string
  renderSuggestion: (data: TSuggestion) => string
  getSuggestionValue: (data: TSuggestion) => string
  fetchSuggestions: (value: string) => Promise<TSuggestion[]>
  onChange: (data: TSuggestion | undefined) => void
  onInput?: (ev: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLElement>, params?: BlurEvent<TSuggestion>) => void
  disabled?: boolean
  expandOnTop?: boolean
  error?: ReactElement | boolean
  className?: string
  placeholder?: string
  mask?: string
  minCharsRequest?: number
  prefix?: ReactNode
  suffix?: ReactNode
}

export default function BaseAutosuggest<TSuggestion>({
  label,
  name,
  tooltip,
  value: superValue,
  getDisplayValue,
  renderSuggestion,
  getSuggestionValue,
  fetchSuggestions,
  onChange,
  onInput,
  onBlur,
  disabled = false,
  expandOnTop = false,
  error,
  className = '',
  placeholder,
  mask,
  minCharsRequest = MINIMUM_CHAR_REQUEST,
  prefix,
  suffix,
}: AutocompleteProps<TSuggestion>): JSX.Element {
  const [suggestions, setSuggestions] = useState<TSuggestion[]>([])
  const [value, setValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const fetch = useCallback(
    async (v: string): Promise<void> => {
      if (v.length < minCharsRequest) {
        return
      }

      setIsLoading(true)

      const newSuggestions = await fetchSuggestions(v)
      setSuggestions(newSuggestions)
      setIsLoading(false)
    },
    [minCharsRequest, setIsLoading, fetchSuggestions, setSuggestions],
  )

  const debouncedFetch = useMemo(() => debounce(300, fetch), [fetch])

  const onSuggestionsFetchRequested = useCallback(
    ({ value: v }: SuggestionsFetchRequestedParams): void => {
      debouncedFetch(v)
    },
    [debouncedFetch],
  )

  const onSuggestionSelected = useCallback(
    (_ev: FormEvent, data: SuggestionSelectedEventData<TSuggestion>): void => {
      debouncedFetch.cancel()
      onChange(data.suggestion)
    },
    [onChange, debouncedFetch],
  )

  const onInputChange = useCallback(
    (event: FormEvent<HTMLElement>, params: AutosuggestChangeEvent): void => {
      setValue(params.newValue)
      if (onInput) {
        const changeEvent = event as ChangeEvent<HTMLInputElement>
        // react-autosuggest sets an intermediate input value of 0 when selecting
        // an element in the list, which is not a valid one.
        if (typeof changeEvent.target.value !== 'number') {
          onInput(changeEvent)
        }
      }
      if (params.newValue === '') {
        onChange(undefined)
      }
    },
    [onChange, onInput],
  )

  useEffect(() => {
    setValue(superValue ? getDisplayValue(superValue) : '')
  }, [getDisplayValue, superValue])

  const wrapperClasses = [
    uiStyle.input,
    error && uiStyle.error,
    disabled && uiStyle.disabled,
    className,
  ].filter(Boolean)

  const elementClasses = [
    uiStyle.input_element,
    style.input,
    value && value.length > 0 && uiStyle.filled,
    prefix && uiStyle.with_prefix,
    suffix && uiStyle.with_suffix,
  ].filter(Boolean)

  const makeRenderSuggestionsContainer = useCallback(
    (loading: boolean): RenderSuggestionsContainer => (autosuggestProps): JSX.Element => {
      const props = { ...autosuggestProps.containerProps }
      let { children } = autosuggestProps
      props.className += ` ${style.suggestions}`

      if (expandOnTop) {
        props.className += ` ${style.top_direction}`
      }

      if (loading) {
        props.className += ' react-autosuggest__suggestions-container--open'
        children = <PageSpinner className={style.loading} />
      }

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <div {...props}>{children}</div>
    },
    [expandOnTop],
  )

  const renderMaskedInput = useCallback<RenderInputComponent>(
    inputProps => {
      if (!mask) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <input {...inputProps} />
      }

      const inputRef = (inputProps.ref as RefCallback<HTMLInputElement> | undefined) || undefined
      const onAccept = (v: unknown): void => {
        if (inputProps.onChange) {
          inputProps.onChange(({
            target: { value: v },
          } as unknown) as ChangeEvent<HTMLInputElement>)
        }
      }

      return (
        <IMaskInput
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...Objects.omit(inputProps, 'onChange')}
          ref={undefined}
          inputRef={inputRef}
          unmask
          onAccept={onAccept}
          mask={mask}
          lazy={false}
        />
      )
    },
    [mask],
  )

  return (
    <Label value={label} tooltip={tooltip} className={uiStyle.field} error={error}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={(): void => setSuggestions([])}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={makeRenderSuggestionsContainer(isLoading)}
        containerProps={{ className: style.wrapper }}
        inputProps={{
          value,
          name,
          onChange: onInputChange,
          onBlur,
          className: elementClasses.join(' '),
          disabled,
          placeholder,
        }}
        renderInputComponent={(props): JSX.Element => (
          <span className={wrapperClasses.join(' ')}>
            {prefix}
            {renderMaskedInput(props)}
            {value && !disabled && (
              <ClearButton
                className={style.clear}
                onClick={(): void => {
                  onChange(undefined)
                  setValue('')
                }}
              />
            )}
            {suffix}
          </span>
        )}
      />
    </Label>
  )
}
