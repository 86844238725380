import type { FormApi } from 'final-form';
import { get, set } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { AnyObject, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';

import { DealState } from '@advitam/api/models/Deal/State';
import { useThunkDispatch } from '@advitam/react';
import { assert } from '@advitam/support';
import { MessageModal } from '@advitam/ui';
import ErrorModal from 'components/ErrorModal';
import ConfirmModal from 'containers/ConfirmModal';
import DialogBox from 'containers/DialogBox';
import { makeSelectDialogIsOpen } from 'containers/DialogBox/selectors.js';
import DocumentGenerationModal from 'containers/DocumentGenerationModal';
import { ActionsPopup } from 'containers/Crud';
import ResourceCable, {
  ResourceNavbarUsers,
  ResourceUpdateModal,
  ResourceUpdatingModal,
} from 'containers/ResourceCable';
import {
  submitErrorsMutators,
  SubmitErrorsSpy,
} from 'lib/final-form-submit-errors';
import { ResourceRoomType } from 'cables/Resource';
import reducerDialogBox from 'containers/DialogBox/reducer.js';
import reducerAutocomplete from 'containers/AutoComplete/reducer.js';
import sagaAutoComplete from 'containers/AutoComplete/sagas.js';
import injectReducer from 'utils/injectReducer.js';
import injectSaga from 'utils/injectSaga.js';

import saga from '../DealFuneral/sagas.js';
import reducer from '../DealFuneral/reducer.js';
import {
  error as setError,
  fetchFuneralDetails,
} from '../DealFuneral/actions.js';
import { makeSelectIsDirty, makeSelectDeal } from '../selectors.typed';
import style from '../Deal.module.scss';
import LayersModal from './LayersModal';
import { Sections } from '../Sections';
import Section from '../Section';
import SectionAlias from '../SectionAlias';
import { refreshDeal } from '../thunk';
import Sidebar from './Sidebar';
import { Path } from './constants';
import {
  makeSelectError,
  makeSelectIsSaving,
  makeSelectFormInitialValues,
} from './selectors';

import SendBatchModal from '../SendBatchModal';
import BookingErrorModal from './ErrorModal';
import SavingLayer from './SavingLayer';
import messages from './messages';
import { saveDealForm } from './thunk';
import type { FuneralForm } from './types';
import { areInitialValuesEqual, focusField } from './utils';

function Funeral(): JSX.Element {
  const dispatch = useDispatch();
  const dispatchAsync = useThunkDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  const deal = useSelector(makeSelectDeal());
  assert(deal !== null);
  const dealState = deal.state;

  const [isSavePreventOpen, setIsSavePreventOpen] = useState(false);

  // TODO: This is compat
  const isDealDirty = useSelector(makeSelectIsDirty());
  const error = useSelector(makeSelectError());
  const isSaving = useSelector(makeSelectIsSaving());
  const isDialogBoxOpen = useSelector(makeSelectDialogIsOpen());
  const initialFormValues = useSelector(makeSelectFormInitialValues());

  // TODO: This is compat
  useEffect(() => {
    if (deal.id) {
      dispatch(fetchFuneralDetails(deal));
    }

    // TODO: Needed ?
    return () => {
      dispatch(setError(null));
    };
  }, [dispatch]);

  const onSavePreventClose = useCallback(() => {
    setIsSavePreventOpen(false);
  }, []);

  const beforeSubmit = useCallback(
    async (form: FormApi<FuneralForm>) => {
      if (dealState === DealState.OK) {
        setIsSavePreventOpen(true);
        return false;
      }

      const { hasValidationErrors, errors } = form.getState();
      if (!hasValidationErrors) {
        return true;
      }

      const errored = form
        .getRegisteredFields()
        .find(field => typeof get(errors, field) === 'string');
      if (errored) {
        await focusField(errored, navigate);
        return true;
      }
      return false;
    },
    [dealState, navigate],
  );

  const onSubmit = useCallback(
    async (values: FuneralForm) => {
      const errors: Record<string, string> = {};
      let inputToFocus: string | null = null;
      await dispatchAsync(
        saveDealForm({
          values,
          addFormError: (field, fieldError) => {
            set(errors, field, fieldError);
            inputToFocus = field;
          },
        }),
      );

      if (!inputToFocus) {
        return undefined;
      }
      await focusField(inputToFocus, navigate);
      return errors;
    },
    [dispatchAsync, navigate],
  );

  const onRefresh = useCallback((): void => {
    assert(deal.uuid !== undefined);
    dispatch(refreshDeal(deal.uuid));
  }, [dispatch, deal]);

  const isSavingOrAreInitialValuesEqual = useCallback(
    (a?: AnyObject, b?: AnyObject) => isSaving || areInitialValuesEqual(a, b),
    [isSaving],
  );

  const dealError = deal.getErrors(intl);
  return (
    <>
      <Helmet>
        <body className="old-design compact-design" />
      </Helmet>

      <Form
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        initialValuesEqual={isSavingOrAreInitialValuesEqual}
        mutators={{ ...submitErrorsMutators }}
      >
        {({ handleSubmit, dirty }): JSX.Element => (
          <form onSubmit={handleSubmit} className={style.container}>
            <Sidebar />
            {/* TODO: `isNew={!deal.id}` once we are not using the state anymore */}
            {(dirty || !deal.id || isDealDirty) && (
              <ActionsPopup isNew beforeSubmit={beforeSubmit} />
            )}

            <Section newDesign path={Path.HOME}>
              <Sections.Home />
            </Section>
            <Section newDesign path={Path.TODOLIST}>
              <Sections.Todolist />
            </Section>
            <Section path={Path.SUMMARY}>
              <Sections.Summary />
            </Section>
            <Section newDesign path={Path.IDENTITY}>
              <Sections.Identity />
            </Section>
            <Section path={Path.STEPS}>
              <Sections.Steps />
            </Section>
            <Section path={Path.DOCUMENTS}>
              <Sections.Documents />
            </Section>
            <Section path={Path.SUPPLIERS}>
              <Sections.Suppliers />
            </Section>
            <SectionAlias from="paiements" to={Path.BILLING} />
            <Section path={Path.BILLING}>
              <Sections.Billing />
            </Section>
            <Section path={Path.MISCELLANEOUS}>
              <Sections.Miscellaneous />
            </Section>
            <SectionAlias from="historique" to={Path.HISTORY} />
            <Section newDesign path={Path.HISTORY}>
              <Sections.History />
            </Section>

            <LayersModal />
            <SubmitErrorsSpy />

            <MessageModal
              isOpen={isSavePreventOpen}
              ctaText={<FormattedMessage id={messages.preventModalCta.id} />}
              message={
                <FormattedMessage id={messages.preventModalMessage.id} />
              }
              title={<FormattedMessage id={messages.preventModalTitle.id} />}
              onClose={onSavePreventClose}
            />
          </form>
        )}
      </Form>
      {deal.id && (
        <ResourceCable
          resourceId={deal.id}
          resourceType={ResourceRoomType.DEAL}
        >
          <ResourceNavbarUsers />
          <ResourceUpdateModal onRefresh={onRefresh} />
          <ResourceUpdatingModal />
        </ResourceCable>
      )}
      <SavingLayer />
      <SendBatchModal />
      {/* TODO: Should not it move as a subcomponent ? */}
      {deal.id && <DocumentGenerationModal dealId={deal.id} />}
      {/* TODO: Part of the "shoud die" serie */}
      {isDialogBoxOpen && <DialogBox />}
      <ConfirmModal />
      <BookingErrorModal />
      {/* TODO: Move to UI */}
      {/* We are using various error formats, we need to clean them before */}
      <ErrorModal error={error || dealError} />
    </>
  );
}

const withReducer = [
  injectReducer({ key: 'dealFuneral', reducer }),
  injectReducer({ key: 'dialogBox', reducer: reducerDialogBox }),
  injectReducer({ key: 'autoComplete', reducer: reducerAutocomplete }),
];
const withSaga = [
  injectSaga({ key: 'dealFuneral', saga }),
  injectSaga({ key: 'autoComplete', saga: sagaAutoComplete }),
];

export default compose<typeof Funeral>(...withReducer, ...withSaga)(Funeral);
