import { createSelector, Selector } from 'reselect';

import type { FlightIndexJSON } from '@advitam/api/models/Flight/FlightIndex';
import type { FlightAirlineJSON } from '@advitam/api/models/Flight/Airline';
import type { FlightAirportJSON } from '@advitam/api/models/Flight/Airport';
import { StepType } from '@advitam/api/models/Step';
import { Arrays, assert } from '@advitam/support';

import { makeSelectDeal } from '../../../../../../selectors.typed';
import type { AppStateSubset as DealAppStateSubset } from '../../../../../../slice';
import type { RepatriationStep } from '../../types';
import type { AppStateSubset, State } from '.';
import { PLANE } from './constants';

type PlaneSelector<T> = Selector<AppStateSubset, T>;
type FullSelector<T> = Selector<AppStateSubset & DealAppStateSubset, T>;

function selectPlaneDomain(state: AppStateSubset): State {
  return state[PLANE];
}

function makeSelectFlights(): PlaneSelector<FlightIndexJSON[]> {
  return createSelector(selectPlaneDomain, state => state.flights);
}

export function makeSelectRepatriationStep(): Selector<
  DealAppStateSubset,
  RepatriationStep
> {
  return createSelector(makeSelectDeal(), deal => {
    assert(deal?.funeral?.steps !== undefined);
    return deal.funeral.steps.find(
      step => step.eventType === StepType.REPATRIATION,
    ) as RepatriationStep;
  });
}

export function makeSelectAirlines(): FullSelector<FlightAirlineJSON[]> {
  return createSelector(
    makeSelectFlights(),
    makeSelectRepatriationStep(),
    (flights, repatriation) => {
      let availableFlights = flights;
      if (repatriation.departure_airport?.id) {
        availableFlights = availableFlights.filter(
          flight =>
            flight.departure_airport.id === repatriation.departure_airport?.id,
        );
      }

      return Arrays.uniqBy(
        availableFlights.map(flight => flight.airline),
        (airline: FlightAirlineJSON) => airline.id,
      );
    },
  );
}

export function makeSelectAirports(): FullSelector<FlightAirportJSON[]> {
  return createSelector(
    makeSelectFlights(),
    makeSelectRepatriationStep(),
    (flights, repatriation) => {
      let availableFlights = flights;
      if (repatriation.trip?.airline_id) {
        availableFlights = availableFlights.filter(
          flight => flight.airline.id === repatriation.trip?.airline_id,
        );
      }
      return Arrays.uniqBy(
        availableFlights.map(flight => flight.departure_airport),
        (airport: FlightAirportJSON) => airport.id,
      );
    },
  );
}

export function makeSelectIsLoading(): PlaneSelector<boolean> {
  return createSelector(selectPlaneDomain, state => state.isLoading);
}
