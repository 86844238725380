import { createAsyncThunk } from '@reduxjs/toolkit';

import Api, { Pagination, request } from '@advitam/api';

import { FLIGHTS } from './constants';
import { makeSelectCurrentPage, makeSelectFilters } from './selectors';
import { AppStateSubset } from './slice';

export const fetchFlights = createAsyncThunk(
  `${FLIGHTS}_FETCH_FLIGHTS`,
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as AppStateSubset;
    const filters = makeSelectFilters()(state);
    const currentPage = makeSelectCurrentPage()(state);
    const arrivalAirport = filters.country && {
      country_id: filters.country?.id,
    };

    try {
      const response = await request(
        Api.V1.Flights.index({
          per_page: 50,
          page: currentPage,
          airline_id: filters.airline?.id,
          arrival_airport: arrivalAirport,
          departure_airport_id: filters.departure_airport?.id,
          q: filters.q,
        }),
      );
      const hasMore = Pagination.getPageCount(response) > currentPage;
      return { flights: response.body, hasMore };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
