import { defineMessages } from '@formatjs/intl';
import { RepatriationType } from 'models/RepatriationType';

export default defineMessages({
  addCrossedBorder: {
    id: 'app.components.DealFuneral.StepEditors.addCrossedBorder',
  },
  addFlight: { id: 'app.components.DealFuneral.StepEditors.addFlight' },
  airCompany: { id: 'app.components.DealFuneral.StepEditors.airCompany' },
  airport: { id: 'app.components.DealFuneral.StepEditors.airport' },
  arrival: { id: 'app.components.DealFuneral.StepEditors.arrival' },
  autoselected: { id: 'app.components.DealFuneral.StepEditors.autoselected' },
  city: { id: 'app.components.DealFuneral.StepEditors.city' },
  civilContact: { id: 'app.components.DealFuneral.StepEditors.civilContact' },
  companyContact: {
    id: 'app.components.DealFuneral.StepEditors.companyContact',
  },
  country: { id: 'app.components.DealFuneral.StepEditors.country' },
  crossedBorders: {
    id: 'app.components.DealFuneral.StepEditors.crossedBorders',
  },
  date: { id: 'app.components.DealFuneral.StepEditors.date' },
  departure: { id: 'app.components.DealFuneral.StepEditors.departure' },
  destination: { id: 'app.components.DealFuneral.StepEditors.destination' },
  flight: { id: 'app.components.DealFuneral.StepEditors.flight' },
  flightNumber: { id: 'app.components.DealFuneral.StepEditors.flightNumber' },
  intermentPlace: {
    id: 'app.components.DealFuneral.StepEditors.intermentPlace',
  },
  LTAnumber: { id: 'app.components.DealFuneral.StepEditors.LTAnumber' },
  search: { id: 'app.components.DealFuneral.StepEditors.search' },
  repatriationType: {
    id: 'app.components.DealFuneral.StepEditors.repatriationType',
  },
  [RepatriationType.PLANE]: {
    id: 'app.components.DealFuneral.StepEditors.plane',
  },
  [RepatriationType.CAR]: {
    id: 'app.components.DealFuneral.StepEditors.car',
  },
});
