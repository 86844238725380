import { createSlice } from '@reduxjs/toolkit';

import type { FlightIndexJSON } from '@advitam/api/models/Flight/FlightIndex';

import { PLANE } from './constants';
import { fetchFlights } from './thunk';

export interface AppStateSubset {
  [PLANE]: State;
}

export interface State {
  flights: FlightIndexJSON[];
  isLoading: boolean;
}

const initialState: State = {
  flights: [],
  isLoading: false,
};

const slice = createSlice({
  name: PLANE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    /* eslint-disable no-param-reassign */
    builder.addCase(fetchFlights.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(fetchFlights.fulfilled, (state, action) => {
      state.flights = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchFlights.rejected, state => {
      state.isLoading = false;
    });
    /* eslint-enable no-param-reassign */
  },
});

export default slice;
