import { FormattedMessage } from 'react-intl';

import type { PersonAddress } from 'models/Person/Legacy';
import SelectAddress from 'components/SelectAddress/index.js';

import type { RepatriationLocation } from '../types';
import Infos from './Infos';
import messages from '../messages';

interface CarProps {
  location: RepatriationLocation | null;
  onChange: (value: PersonAddress) => void;
}

export default function Car({ location, onChange }: CarProps): JSX.Element {
  return (
    <>
      <FormattedMessage id={messages.search.id} />
      <br />
      <SelectAddress
        address={location?.address}
        onChange={(value: PersonAddress): void => onChange(value)}
      />
      {location && (
        <Infos
          city={location.address.city || null}
          country={location.address.country || null}
        />
      )}
    </>
  );
}
