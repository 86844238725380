import { useLayoutEffect, useState } from 'react'

export function useOmniauthError(): string | null {
  const [error, setError] = useState<string | null>(null)

  useLayoutEffect(() => {
    const origin = new URL(window.location.href)
    setError(origin.searchParams.get('error'))
    origin.searchParams.delete('error')
  }, [])

  return error
}
