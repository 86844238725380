// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout-module__main--QBYRR{height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center}.Layout-module__main--QBYRR h2{margin:0 0 16px}.Layout-module__content--OZu4z{max-width:calc(100% - 16px)}`, "",{"version":3,"sources":["webpack://./src/containers/Auth/parts/Layout.module.scss"],"names":[],"mappings":"AAAA,4BACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CAEA,+BACE,eAAA,CAIJ,+BACE,2BAAA","sourcesContent":[".main {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n\n  h2 {\n    margin: 0 0 16px;\n  }\n}\n\n.content {\n  max-width: calc(100% - 16px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Layout-module__main--QBYRR`,
	"content": `Layout-module__content--OZu4z`
};
export default ___CSS_LOADER_EXPORT___;
