import {
  CREMATORIUMS_PATH,
  WORSHIPS_PATH,
  CITYHALLS_PATH,
  HRA_PATH,
  PREFECTURE_PATH,
  GRAVEYARD_PATH,
  FUNERAL_PARLOR_PATH,
  HOSPITAL_PATH,
  POLICES_PATH,
  Path,
} from 'containers/App/constants.ts';

export const DEFAULT_DEFUNCT_AGE = 60;

export const DEAL_FUNERAL_SPACE = 'Deal/Funeral';

export const FETCH_FUNERAL_DETAILS = 'app/DealFuneral/FETCH_FUNERAL_DETAILS';

export const GET_DEAL_OWNER = 'app/DealFuneral/GET_DEAL_OWNER';
export const SET_DEAL_OWNER = 'app/DealFuneral/SET_DEAL_OWNER';

export const QUERY_UPDATE_DEAL_OWNER =
  'app/DealFuneral/QUERY_UPDATE_DEAL_OWNER';

export const ERROR = 'app/DealFuneral/ERROR';

/*
 *  Steps section constants
 */
export const GET_CREMATORIUM = 'app/DealFuneral/GET_CREMATORIUM';
export const SET_CREMATORIUM = 'app/DealFuneral/SET_CREMATORIUM';
export const GET_CEREMONIES = 'app/DealFuneral/GET_CEREMONIES';
export const SET_CEREMONIES = 'app/DealFuneral/SET_CEREMONIES';
export const SET_WORSHIP_TYPES = 'app/DealFuneral/SET_WORSHIP_TYPES';
export const GET_CONCESSION_PRICES = 'app/DealFuneral/GET_CONCESSION_PRICES';
export const SET_CONCESSION_PRICES = 'app/DealFuneral/SET_CONCESSION_PRICES';
export const SET_PENDING_PRODUCT = 'app/DealFuneral/SET_PENDING_PRODUCT';
export const REMOVE_SERVICE = 'app/DealFuneral/REMOVE_SERVICE';
export const GET_CONCESSION_TYPES = 'app/DealFuneral/GET_CONCESSION_TYPES';
export const SET_CONCESSION_TYPES = 'app/DealFuneral/SET_CONCESSION_TYPES';
export const GET_FUNERAL_PARLOR_STAY_TYPES =
  'app/DealFuneral/GET_FUNERAL_PARLOR_STAY_TYPES';
export const SET_FUNERAL_PARLOR_STAY_TYPES =
  'app/DealFuneral/SET_FUNERAL_PARLOR_STAY_TYPES';

/*
 *  SideBar section constants
 */
export const GET_DEAL_SUMMARY = 'app/DealFuneral/GET_DEAL_SUMMARY';
export const SET_DEAL_SUMMARY = 'app/DealFuneral/SET_DEAL_SUMMARY';

export const STEP_DEATH = 'death';
export const STEP_TBC = 'transportBeforeCasketing';
export const STEP_CASKETING = 'casketing';
export const STEP_CLOSING = 'closing';
export const STEP_TAC = 'transportAfterCasketing';
export const STEP_CREMATION = 'cremation';
export const STEP_REPATRIATION = 'repatriation';
export const STEP_CEREMONY = 'ceremony';
export const STEP_INTERMENT = 'interment';
export const STEP_URN_PICKUP = 'urnPickup';
export const STEP_URN_SEALING = 'urnSealing';
export const STEP_DEPOSIT = 'deposit';

export const POLICE = 'police';
export const ADVITAM = 'advitam';
export const OTHER = 'other';

export const PUBLIC = 'Public';
export const HEALTH_FACILITY = 'HealthFacility';
export const FUNERAL_PARLOR = 'FuneralParlor';
export const PUBLIC_LOCATION = 'PublicLocation';
export const FOREIGN_FUNERAL_CONTACT = 'ForeignFuneralContact';

export const ENTITY_HOSPITAL = 'Hospital';
export const ENTITY_FUNERAL_PARLOR = 'FuneralParlor';
export const ENTITY_CITYHALL = 'Cityhall';
export const ENTITY_CREMATORIUM = 'Crematorium';
export const ENTITY_GRAVEYARD = 'Graveyard';
export const ENTITY_POLICE = 'Police';
export const ENTITY_PREFECTURE = 'Prefecture';
export const ENTITY_RHA = 'RegionalHealthAuthority';
export const ENTITY_WORSHIP = 'Worship';
export const ENTITY_CONSULATE = 'Consulate';

export const ENTITY_TYPE_TO_PATH = {
  [ENTITY_FUNERAL_PARLOR]: FUNERAL_PARLOR_PATH,
  [ENTITY_HOSPITAL]: HOSPITAL_PATH,
  [HEALTH_FACILITY]: HOSPITAL_PATH,
  [FUNERAL_PARLOR]: FUNERAL_PARLOR_PATH,
  [ENTITY_CREMATORIUM]: CREMATORIUMS_PATH,
  [ENTITY_CITYHALL]: CITYHALLS_PATH,
  [ENTITY_PREFECTURE]: PREFECTURE_PATH,
  [ENTITY_WORSHIP]: WORSHIPS_PATH,
  [ENTITY_RHA]: HRA_PATH,
  [ENTITY_GRAVEYARD]: GRAVEYARD_PATH,
  [ENTITY_POLICE]: POLICES_PATH,
  [ENTITY_CONSULATE]: Path.CONSULATES,
};
