import { updateDeal as updateDealSlice } from '../slice.ts';
import { FETCH_FUNERAL_DETAILS, ERROR } from './constants';

export function fetchFuneralDetails(deal) {
  return {
    type: FETCH_FUNERAL_DETAILS,
    deal,
  };
}

/**
 * Action to dispatch to update the deal in the reducer
 *
 * @param      {unknown}  updateObj  The update object
 *                                  object used to update the deal state
 * @param      {unknown}  funeral   initial value of funeral
 * @return     {Object}  An action object asking the reducer to update the deal
 *                       using the update object provided above
 */
export function updateDeal(updateObj, funeral) {
  return updateDealSlice({
    funeral: {
      ...funeral,
      ...updateObj,
    },
  });
}

export function updateWishes(wishType, active, deal, dealType) {
  const tempWishes = [...deal.wishes];
  let wishFound = false;
  const finalWishes = tempWishes.map(wish => {
    if (wish.type === wishType) {
      wishFound = true;
      return { active, type: wish.type, value: wish.value };
    }
    return wish;
  });
  if (wishFound === false) {
    finalWishes.push({
      active,
      type: wishType,
    });
  }
  return updateDealSlice({
    [dealType]: {
      ...deal,
      wishes: finalWishes,
    },
  });
}

export function error(err) {
  return {
    type: ERROR,
    err,
  };
}
