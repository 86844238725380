import { FormattedDate, FormattedMessage } from 'react-intl';

import type { ClientAbilityIndexJSON } from '@advitam/api/models/Client/Ability/IndexAbility';
import { ResourceType } from '@advitam/api/models/Client/Ability/ResourceType';
import { LinkButton } from '@advitam/ui/components/Button';
import { Person } from '@advitam/ui/components/Format';
import ResourceList from '@advitam/ui/components/ResourceList';
import Tooltip from '@advitam/ui/components/Tooltip';
import OpenIcon from '@advitam/ui/images/icons/arrow-up-right-from-square.svg';
import DealStateFlag from 'components/DealStateFlag';

import { getCategoryMessage, getLink, getStatusMessage } from '../utils';
import messages from '../messages';
import style from '../Resources.module.scss';

interface RowProps {
  resource: ClientAbilityIndexJSON;
}

export default function Row({ resource }: RowProps): JSX.Element {
  const link = getLink(resource);

  return (
    <ResourceList.Row className={style.row}>
      <ResourceList.Cell hiddenOnTablet className={style.state}>
        {resource.resource_type === ResourceType.DEAL ? (
          <DealStateFlag state={resource.resource.state} />
        ) : (
          <span />
        )}
      </ResourceList.Cell>
      <ResourceList.Cell className={style.id}>
        {resource.resource_type === ResourceType.DEAL ? (
          resource.resource.business_id
        ) : (
          <FormattedDate value={resource.resource.created_at} />
        )}
      </ResourceList.Cell>
      <ResourceList.Cell className={style.category}>
        <FormattedMessage id={getCategoryMessage(resource).id} />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.owner}>
        {resource.resource.owner ? (
          <Person.FormattedName person={resource.resource.owner} />
        ) : (
          <FormattedMessage id={messages.anonymized.id} />
        )}
      </ResourceList.Cell>
      <ResourceList.Cell className={style.user_status}>
        <FormattedMessage id={getStatusMessage(resource).id} />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.actions}>
        {link && (
          <Tooltip content={<FormattedMessage id={messages.view.id} />}>
            <LinkButton outline newTab href={link} icon={<OpenIcon />} />
          </Tooltip>
        )}
      </ResourceList.Cell>
    </ResourceList.Row>
  );
}
