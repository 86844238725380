import { authenticate, withSessionId } from '../../lib/decorators'
import { del, get, post, put } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'
import type { FlightJSON } from '../../models/Flight'
import type { FlightIndexJSON } from '../../models/Flight/FlightIndex'
import { Disabled } from './Disabled'
import { serialize } from './serializers'

export interface FlightsIndexFilters {
  q?: string
  airline_id?: number
  arrival_airport?: { country_id: number }
  arrival_airport_id?: number
  departure_airport_id?: number
  page?: number
  per_page?: number
  supplier_warehouse_id?: number
}

export const Flights = {
  Disabled,

  index: (filters: FlightsIndexFilters): ApiRequestDescriptor<FlightIndexJSON[]> =>
    authenticate(get('/api/v1/flights', filters)),

  show: (id: number): ApiRequestDescriptor<FlightJSON> =>
    authenticate(get(`/api/v1/flights/${id}`)),

  create: (flight: Omit<FlightJSON, 'id'>): ApiRequestDescriptor<FlightJSON> =>
    withSessionId(authenticate(post(`/api/v1/flights`, serialize(flight)))),

  update: (flight: FlightJSON): ApiRequestDescriptor<FlightJSON> =>
    withSessionId(authenticate(put(`/api/v1/flights/${flight.id}`, serialize(flight)))),

  destroy: (id: number): ApiRequestDescriptor<void> => authenticate(del(`/api/v1/flights/${id}`)),
}
