import { useCallback, KeyboardEvent } from 'react'

import { Autosuggest, DateRange, Input, NumberInput, Select, Switch } from '../../Form/UI'
import type { AutocompleteResult } from '../../Form/UI/Autosuggest/types'

import type { Filter as FilterModel, FilterSelection, FilterValue } from './types'
import style from './Filters.module.scss'

interface FilterProps {
  filter: FilterModel
  selectedFilters: Record<string, FilterSelection>
  onChange: (value: Record<string, FilterSelection>) => void
}

export default function Filter({
  filter,
  selectedFilters,
  onChange,
}: FilterProps): JSX.Element | null {
  const onFilterKey = useCallback((event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
    }
  }, [])

  if (filter.isHidden) {
    return null
  }

  if (filter.type === 'Autocomplete') {
    return (
      <Autosuggest
        className={style.autocomplete}
        requestHeaders={filter.requestHeaders}
        endpoint={filter.endpoint}
        placeholder={filter.placeholder}
        getDisplayValue={filter.getDisplayValue}
        value={selectedFilters[filter.name] as AutocompleteResult}
        onChange={(item?: AutocompleteResult): void => onChange({ [filter.name]: item })}
        disabled={filter.disabled}
      />
    )
  }

  if (filter.type === 'DateRange') {
    return (
      <DateRange<string>
        startValue={selectedFilters[filter.startName] as string}
        endValue={selectedFilters[filter.endName] as string}
        onChange={(ev): void => {
          onChange({
            [filter.startName]: ev.startValue,
            [filter.endName]: ev.endValue,
          })
        }}
      />
    )
  }

  if (filter.type === 'Switch') {
    return (
      <Switch
        label={filter.placeholder}
        value={filter.name}
        checked={selectedFilters[filter.name] as boolean}
        onChange={(): void => onChange({ [filter.name]: !selectedFilters[filter.name] })}
      />
    )
  }

  if (filter.type === 'Text') {
    return (
      <Input
        placeholder={filter.placeholder}
        value={(selectedFilters[filter.name] as string | undefined) ?? filter.initialValue ?? null}
        onChange={(ev): void => onChange({ [filter.name]: ev.target.value })}
        onKeyDown={onFilterKey}
        onKeyUp={onFilterKey}
      />
    )
  }

  if (filter.type === 'Number') {
    return (
      <NumberInput
        placeholder={filter.placeholder}
        value={
          (selectedFilters[filter.name] as number | undefined) ?? filter.initialValue ?? undefined
        }
        onChange={(ev): void => onChange({ [filter.name]: ev.target.value })}
        onKeyDown={onFilterKey}
        onKeyUp={onFilterKey}
        step={filter.step}
        suffix={filter.suffix}
      />
    )
  }

  return (
    <Select
      placeholder={filter.placeholder}
      items={filter.items.map(item => ({
        ...item,
        name: item.name,
      }))}
      onChange={(value): void => onChange({ [filter.name]: value })}
      value={(selectedFilters[filter.name] as FilterValue) ?? filter.initialValue}
      multiple={filter.multiple}
      filter={filter.filter}
      unselectable
    />
  )
}
