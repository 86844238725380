import { useCallback, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import type { RateJSON } from '@advitam/api/models/Flight/Rate';
import { assert } from '@advitam/support';
import { Button, Filter, FilterSelection, ResourceList } from '@advitam/ui';
import { messages as crudMessages } from 'containers/Crud';

import messages from '../../messages';
import {
  makeSelectRateSuppliersFilterItems,
  makeSelectRawFlight,
} from '../../selectors';
import type { FlightForm } from '../types';
import type { RateForm, RatesFilters } from './types';
import { setFilters } from './slice';
import style from './Rates.module.scss';

export default function Filters(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const flight = useSelector(makeSelectRawFlight());
  assert(flight !== null);
  const supplierItems = useSelector(makeSelectRateSuppliersFilterItems());

  const form = useForm<FlightForm & RateForm>();
  const { values } = useFormState<FlightForm>();
  const { rates } = values;

  const filters: Filter[] = useMemo(
    () => [
      {
        type: 'Select',
        name: 'suppliers',
        placeholder: intl.formatMessage(messages.supplier),
        multiple: true,
        filter: true,
        items: supplierItems,
        initialValue: [],
      },
      {
        type: 'Number',
        name: 'weight',
        placeholder: intl.formatMessage(messages.maxWeight),
        step: 10,
        suffix: <FormattedMessage id={crudMessages.unitKilogram.id} />,
      },
      {
        type: 'Switch',
        name: 'activeSupplier',
        placeholder: <FormattedMessage id={messages.activeSupplier.id} />,
        initialValue: true,
      },
    ],
    [intl, supplierItems],
  );

  const addRate = useCallback(() => {
    const updatedRates = [{} as RateJSON, ...rates];
    form.change('rates', updatedRates);
  }, [form, rates]);

  const onChange = useCallback(
    (filterValues: Record<string, FilterSelection>) => {
      dispatch(setFilters((filterValues as unknown) as RatesFilters));
    },
    [dispatch],
  );

  return (
    <ResourceList.Filters filters={filters} onChange={onChange}>
      <Button
        primary
        onClick={addRate}
        text={<FormattedMessage id={messages.addRate.id} />}
        className={style.add_rate}
      />
    </ResourceList.Filters>
  );
}
