import { FC, useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import { HardSpinner, ResourceList } from '@advitam/ui';
import { Layout } from 'containers/Crud';
import { withDatasets } from 'slices/data';

import { updateSupplierName } from '../../thunk';
import type { SupplierForm } from '../types';
import Wrapper from '../Wrapper';
import WarehousesFilters from './parts/Filters';
import WarehousesHeader from './parts/Header';
import WarehousesRow from './parts/Row';
import DestroyModal from './DestroyModal';
import DisableModal from './DisableModal';
import {
  makeSelectHasMore,
  makeSelectIsLoading,
  makeSelectIsSaving,
  makeSelectHasWarehouseInUseError,
  makeSelectWarehouses,
} from './selectors';
import slice, { resetDeletionErrors, setFilters } from './slice';
import { fetchWarehouses } from './thunk';

function Warehouses(): JSX.Element {
  const dispatch = useDispatch();
  const isLoading = useSelector(makeSelectIsLoading());
  const isSaving = useSelector(makeSelectIsSaving());
  const warehouses = useSelector(makeSelectWarehouses());
  const hasMore = useSelector(makeSelectHasMore());

  const [warehouseIdToDelete, setWarehouseIdToDelete] = useState<number | null>(
    null,
  );

  const warehouseToDelete = warehouses.find(
    warehouse => warehouse.id === warehouseIdToDelete,
  );

  const hasInUseError = useSelector(makeSelectHasWarehouseInUseError());
  const shouldDisable =
    warehouseToDelete && !warehouseToDelete?.disabled && hasInUseError;

  const onSubmit = (values: SupplierForm): void => {
    dispatch(updateSupplierName(values.supplier.name));
  };

  const onCloseDeletionModal = useCallback(() => {
    setWarehouseIdToDelete(null);
    dispatch(resetDeletionErrors());
  }, [dispatch, setWarehouseIdToDelete]);

  const fetchResources = useCallback(() => {
    dispatch(fetchWarehouses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setFilters({}));
    fetchResources();
  }, [dispatch, fetchResources]);

  return (
    <>
      {isSaving && <HardSpinner />}

      <Wrapper onSubmit={onSubmit}>
        <Layout.Container>
          <ResourceList.Container
            filters={<WarehousesFilters />}
            header={<WarehousesHeader />}
            isLoading={isLoading}
            hasMore={hasMore}
            fetchResources={fetchResources}
          >
            {warehouses.map(warehouse => (
              <WarehousesRow
                key={warehouse.id}
                warehouse={warehouse}
                onDelete={(): void => setWarehouseIdToDelete(warehouse.id)}
              />
            ))}
          </ResourceList.Container>
        </Layout.Container>
      </Wrapper>

      {warehouseToDelete !== undefined &&
        (shouldDisable ? (
          <DisableModal
            warehouseToDelete={warehouseToDelete}
            onClose={onCloseDeletionModal}
          />
        ) : (
          <DestroyModal
            warehouseToDelete={warehouseToDelete}
            onClose={onCloseDeletionModal}
          />
        ))}
    </>
  );
}

export default compose<FC<Record<string, never>>>(
  withSlice(slice),
  withDatasets('departments'),
)(Warehouses);
