import { createAsyncThunk } from '@reduxjs/toolkit';

import { assert } from '@advitam/support';
import Api, { requestAsync } from 'api';
import { Role } from '@advitam/api/models/BusinessUser/Role';
import { BookingStatus } from 'models/BookingStatus';
import { PaymentProblem } from 'models/Deal/PaymentProblem';
import { PaymentJSON } from 'models/Payment';
import {
  makeSelectUser,
  AppStateSubset as UserAppStateSubset,
} from 'slices/auth';

import { makeSelectBookingStatus } from '../BookingSection/selectors.js';
import { fetchItems } from '../Sections/Todolist/thunk';
import { AppStateSubset as DealAppStateSubset } from '../slice';
import { AppStateSubset as PaymentsAppStateSubset } from './slice';
import { PAYMENTS_SPACE } from './constants';
import { makeSelectPayments, makeSelectPaymentsToRemove } from './selectors';

type AppStateSubset = DealAppStateSubset & PaymentsAppStateSubset;

export function consolidatePayment(payment: PaymentJSON): PaymentJSON {
  if (payment.is_company) {
    return {
      ...payment,
      first_name: null,
      last_name: null,
      civ: null,
    };
  }

  return {
    ...payment,
    company_name: null,
  };
}

export const fetchPayments = createAsyncThunk(
  `${PAYMENTS_SPACE}/FETCH_PAYMENTS`,
  async (uuid: string, { rejectWithValue }) => {
    try {
      const { body } = await requestAsync(Api.V1.Deals.Payments.show(uuid));
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchPaymentsDetails = createAsyncThunk(
  `${PAYMENTS_SPACE}/FETCH_PAYMENTS_DETAILS`,
  async (uuid: string, { dispatch }) => {
    await dispatch(fetchPayments(uuid));
  },
);

export const postPayments = createAsyncThunk(
  `${PAYMENTS_SPACE}/POST_PAYMENTS`,
  async (uuid: string, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as AppStateSubset & UserAppStateSubset;
    const payments = makeSelectPayments()(state);
    const paymentsToRemove = makeSelectPaymentsToRemove()(state);
    const bookingStatus = makeSelectBookingStatus()(state);
    const user = makeSelectUser()(state);
    assert(user !== null);

    let paymentsToUpdate = payments.map(consolidatePayment);
    if (user.role !== Role.ADMIN && bookingStatus === BookingStatus.BOOKED) {
      paymentsToUpdate = paymentsToUpdate.filter(payment => !payment.uuid);
    }

    try {
      const { body } = await requestAsync(
        Api.V1.Deals.Payments.update(uuid, {
          payments: paymentsToUpdate,
          deleted: paymentsToRemove,
        }),
      );
      assert(body !== null);

      await dispatch(fetchItems());

      return {
        ...body,
        payments: [
          ...body.payments,
          ...payments.filter(
            payment =>
              payment.uuid && !body.payments.find(p => p.uuid === payment.uuid),
          ),
        ],
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

interface UpdatePaymentProblemArgs {
  uuid: string;
  paymentProblem: PaymentProblem;
}

export const updatePaymentProblem = createAsyncThunk(
  `${PAYMENTS_SPACE}/UPDATE_PAYMENT_PROBLEM`,
  async (payload: UpdatePaymentProblemArgs, { rejectWithValue }) => {
    try {
      const { uuid, paymentProblem } = payload;

      await requestAsync(
        Api.V1.Deals.PaymentProblem.update(uuid, paymentProblem),
      );

      return payload.paymentProblem;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
