import { ReactNode } from 'react'
import { AutocompleteResult } from '../../Form/UI/Autosuggest/types'

export type FilterValue = number | string | boolean | Date
export type FilterSelection = AutocompleteResult | FilterValue | FilterValue[] | undefined

export interface FilterItem {
  name: string
  value: FilterValue
}
interface FilterBase {
  type: string
  isHidden?: boolean
  placeholder?: ReactNode
}

export interface AutocompleteFilter extends FilterBase {
  type: 'Autocomplete'
  name: string
  endpoint: string
  placeholder?: string
  requestHeaders?: Record<string, string>
  initialValue?: FilterSelection
  disabled?: boolean
  getDisplayValue?: (value: AutocompleteResult) => string
}

export interface DateRangeFilter extends FilterBase {
  type: 'DateRange'
  startName: string
  endName: string
  initialStartValue?: Date
  initialEndValue?: Date
}

export interface SearchFilter {
  name: string
  placeholder?: string
  initialValue?: FilterValue
}

export interface SelectFilter extends FilterBase {
  type: 'Select'
  name: string
  initialValue?: FilterValue | FilterValue[]
  multiple?: boolean
  filter?: boolean
  items: FilterItem[]
}

export interface SwitchFilter extends FilterBase {
  type: 'Switch'
  name: string
  initialValue?: boolean
}

export interface TextFilter extends FilterBase {
  type: 'Text'
  name: string
  placeholder?: string
  initialValue?: string
}

export interface NumberFilter extends FilterBase {
  type: 'Number'
  name: string
  placeholder?: string
  initialValue?: number
  step?: number
  suffix?: ReactNode
}

export type Filter =
  | AutocompleteFilter
  | SelectFilter
  | DateRangeFilter
  | SwitchFilter
  | TextFilter
  | NumberFilter

export function isDateRangeFilter(filter: Filter): filter is DateRangeFilter {
  return filter.type === 'DateRange'
}
