import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormUI, ResourceList } from '@advitam/ui';

import messages from '../../messages';
import style from './Rates.module.scss';

function p(chunk: ReactNode): ReactNode {
  return <p>{chunk}</p>;
}

export default function Header(): JSX.Element {
  return (
    <ResourceList.Header>
      <ResourceList.Cell>
        <FormattedMessage id={messages.maxWeight.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.price.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.supplier.id} />
      </ResourceList.Cell>
      <ResourceList.Cell className={style.deposit_days_header}>
        <FormattedMessage id={messages.depositDays.id} />
        <FormUI.Tooltip
          content={
            <FormattedMessage
              id={messages.depositDaysTooltip.id}
              values={{ p }}
            />
          }
        />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.lastUpdate.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet />
      <ResourceList.Cell>
        <FormattedMessage id={messages.actions.id} />
      </ResourceList.Cell>
    </ResourceList.Header>
  );
}
