import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  actions: { id: 'app.containers.Deal.Sections.Todolist.List.actions' },
  book: { id: 'app.containers.Deal.Sections.Todolist.List.book' },
  createAccount: {
    id: 'app.containers.Deal.Sections.Todolist.List.createAccount',
  },
  requestFlightPrice: {
    id: 'app.containers.Deal.Sections.Todolist.List.requestFlightPrice',
  },
  searchForDefunct: {
    id: 'app.containers.Deal.Sections.Todolist.List.searchForDefunct',
  },
  deleteTask: { id: 'app.containers.Deal.Sections.Todolist.List.deleteTask' },
  send: { id: 'app.containers.Deal.Sections.Todolist.List.send' },
  task: { id: 'app.containers.Deal.Sections.Todolist.List.task' },
});
