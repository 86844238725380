// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OmniAuth-module__omni_auth--Mwfnu svg{width:24px;height:24px}`, "",{"version":3,"sources":["webpack://./../integration/src/components/OmniAuth/OmniAuth.module.scss"],"names":[],"mappings":"AACE,uCACE,UAAA,CACA,WAAA","sourcesContent":[".omni_auth {\n  svg {\n    width: 24px;\n    height: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"omni_auth": `OmniAuth-module__omni_auth--Mwfnu`
};
export default ___CSS_LOADER_EXPORT___;
