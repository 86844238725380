import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { TodoItem } from '@advitam/api/models/Deal/TodoItem';
import SendIcon from '@advitam/ui/images/icons/send.svg';

import { makeSelectIsItemLoading } from '../../selectors';
import { requestFlightsPrice } from '../../thunk';
import Action from '../parts/Action';
import Task from '../parts/Task';
import messages from '../messages';

interface RequestFlightsPriceProps {
  item: TodoItem;
}

export default function RequestFlightsPrice({
  item,
}: RequestFlightsPriceProps): JSX.Element {
  const dispatch = useDispatch();
  const isLoading = useSelector(makeSelectIsItemLoading(item));

  const onRequestFlightsPrice = (): void => {
    dispatch(requestFlightsPrice());
  };

  return (
    <Task
      item={item}
      action={
        <Action
          icon={<SendIcon />}
          message={<FormattedMessage id={messages.requestFlightPrice.id} />}
          disabled={isLoading}
          onClick={onRequestFlightsPrice}
        />
      }
    />
  );
}
