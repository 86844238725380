import { RepatriationType } from '@advitam/api/models/Repatriation/Type';
import type { PersonAddress } from 'models/Person/Legacy';
import { PlaceType } from 'models/Place';

import type { RepatriationStep } from '../types';
import Car from './Car';
import Plane from './Plane';

interface DestinationProps {
  step: RepatriationStep;
  onChange: (value: Partial<RepatriationStep>) => void;
}

export default function Destination({
  step,
  onChange,
}: DestinationProps): JSX.Element {
  const onCarChange = (value: PersonAddress): void => {
    onChange({
      location: {
        address: value,
        name: value.address || '',
        sameAsPreviousStep: false,
        type: PlaceType.PUBLIC,
      },
    });
  };

  if (step.repatriation_type === RepatriationType.CAR) {
    return <Car location={step.location} onChange={onCarChange} />;
  }

  return <Plane step={step} onChange={onChange} />;
}
