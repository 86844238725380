import { defineMessages } from 'react-intl';

export default defineMessages({
  forgottenPassword: { id: 'app.containers.Auth.Login.forgottenPassword' },
  login: { id: 'app.containers.Auth.Login.login' },
  loginWith: { id: 'app.containers.Auth.Login.loginWith' },
  title: { id: 'app.containers.Auth.Login.title' },
  password: { id: 'app.containers.Auth.Login.password' },
  unauthorized: { id: 'app.containers.Auth.Login.unauthorized' },
});
