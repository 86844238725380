import { authenticate, withSessionId } from '../../lib/decorators'
import { put } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'
import type { DealKoReason } from '../../models/Deal/KoReason'
import type { DealState, DealStateJSON } from '../../models/Deal/State'

interface UpdatePayload {
  ko_comment: string | null
  ko_reason: DealKoReason | null
  state: DealState
}

export const State = {
  update: (dealUuid: string, state: UpdatePayload): ApiRequestDescriptor<DealStateJSON> =>
    withSessionId(authenticate(put(`/api/v1/deals/${dealUuid}/state`, state))),
}
