import type { RateJSON } from '@advitam/api/models/Flight/Rate';
import { Model } from '@advitam/api/models/Model';

import type { RatesFilters } from './types';

export function isFilteredOut(rate: RateJSON, filters: RatesFilters): boolean {
  const { suppliers, weight, activeSupplier } = filters;

  return (
    Model.isDestroyed(rate) ||
    (suppliers &&
      suppliers.length > 0 &&
      !suppliers.some(supplier => rate.supplier_warehouse_id === supplier)) ||
    Boolean(weight && rate.weight < weight) ||
    Boolean(activeSupplier && rate.disabled_supplier)
  );
}
