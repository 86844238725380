import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RatesFilters } from './types';
import { FLIGHT_FILTERS } from './constants';

export interface State {
  filters: RatesFilters;
}

export interface AppStateSubset {
  [FLIGHT_FILTERS]: State;
}

const initialState: State = {
  filters: {
    suppliers: [],
    activeSupplier: true,
  },
};

const slice = createSlice({
  name: FLIGHT_FILTERS,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setFilters(state, action: PayloadAction<RatesFilters>) {
      state.filters = action.payload;
    },
    /* eslint-enable no-param-reassign */
  },
});

export const { setFilters } = slice.actions;
export default slice;
