import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { PaymentMean } from 'models/Payment/Mean.ts';

import Label from 'components/Label';
import AutoComplete from 'containers/AutoComplete';
import { canUserEditPayment } from '../../utils.ts';
import messages from '../messages.ts';

import {
  FILTER_BANK,
  FILTER_LIFE_INSURANCE,
  FILTER_HEALTH_INSURANCE,
} from './constants.ts';

/**
 * FundingAgency
 *
 * Render funding agency form
 */
export class FundingAgency extends PureComponent {
  /**
   * Set response of autocomplete funding_agencies
   *
   * @param {Object} fundingAgency Id and name of the funding agency
   */
  setFundingAgency = fundingAgency => {
    const { handleChange, payment } = this.props;
    handleChange({
      ...payment,
      funding_agency: fundingAgency,
    });
  };

  /**
   * Function to get funding agency params for autocomplete
   *
   * @param {string} paymentMean mean of payment
   */
  getFundingAgencyAutocompleteParams = paymentMean => {
    switch (paymentMean) {
      case PaymentMean.FUNERAL_CONTRACT:
        return [FILTER_LIFE_INSURANCE];
      case PaymentMean.DEFUNCT_ACCOUNT:
        return [FILTER_BANK, FILTER_HEALTH_INSURANCE];
      default:
        return [];
    }
  };

  renderLabel = () => {
    const { payment } = this.props;
    if (payment.mean !== PaymentMean.CHECK) {
      return <FormattedMessage {...messages.fundingAgency} />;
    }
    return <FormattedMessage {...messages.fundingAgencyCheck} />;
  };

  render() {
    const {
      payment,
      userRole,
      isNewPayment,
      disabled,
      bookingStatus,
    } = this.props;

    if (!payment.funding_agency) {
      return (
        <div className="payment__row">
          {this.renderLabel()}
          <div>
            <AutoComplete
              url="/api/v1/autocompletes/funding_agencies?q="
              getKey={item => item.id}
              getValue={item => item.description}
              onSelect={(_, item) =>
                this.setFundingAgency({
                  id: item.id,
                  name: item.description,
                })
              }
              auth
              fullWidth
              paramsToAddToQuery={{
                type: this.getFundingAgencyAutocompleteParams(payment.mean),
              }}
              disabled={disabled}
            />
          </div>
        </div>
      );
    }

    const isUserAuthorized = canUserEditPayment(
      payment.state,
      isNewPayment,
      bookingStatus,
      userRole,
    );

    return (
      <div className="payment__row">
        <div className="payment__row__fundingAgency">
          {this.renderLabel()}
          {isUserAuthorized && !disabled ? (
            <Label
              onRemove={() => this.setFundingAgency(null)}
              name={payment.funding_agency.name}
            />
          ) : (
            <span>{payment.funding_agency.name}</span>
          )}
        </div>
      </div>
    );
  }
}

FundingAgency.propTypes = {
  /** Payment data */
  payment: PropTypes.object.isRequired,
  /** Tell if the user is a funeral director or not */
  userRole: PropTypes.number.isRequired,
  /** Tell if it's a new payment or not */
  isNewPayment: PropTypes.bool,
  disabled: PropTypes.bool,
  /** Function to update payment */
  handleChange: PropTypes.func.isRequired,
  bookingStatus: PropTypes.string.isRequired,
};

export default FundingAgency;
