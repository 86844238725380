import { authenticate } from '../../lib/decorators'
import { get } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'
import type { PerkJSON } from '../../models/Perk'
import { Usages } from './Usages'

export interface PerksFilters {
  page?: number
  per_page?: number
  q?: string
  category_id_eq?: number | null
  department_codes?: string[] | null
}

export const Perks = {
  Usages,

  index: (filters?: PerksFilters): ApiRequestDescriptor<PerkJSON[]> =>
    authenticate(get('/api/v1/perks', filters)),
}
