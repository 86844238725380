import { createSelector } from 'reselect';
import { makeSelectDeal } from 'containers/Deal/selectors';

/**
 * Direct selector to the dealFuneral state domain
 */
const selectDealFuneralDomain = state => state.dealFuneral;

const makeSelectLoading = () =>
  createSelector(selectDealFuneralDomain, substate => substate.loading);

const makeSelectError = () =>
  createSelector(selectDealFuneralDomain, substate => substate.error);

const makeSelectStepDoneByOtherFuneral = () =>
  createSelector(makeSelectDeal(), substate => {
    if (!substate || !substate.funeral) return false;
    const { steps } = substate.funeral;
    if (!steps) return false;
    for (let i = 0; i < steps.length; i += 1) {
      if (steps[i] && steps[i].doneByAnotherFuneral === true) return true;
    }
    return false;
  });

const makeSelectManualSelection = () =>
  createSelector(makeSelectDeal(), substate =>
    substate ? substate.manualSelection : false,
  );

const makeSelectFuneral = () =>
  createSelector(makeSelectDeal(), substate =>
    substate ? substate.funeral : null,
  );

export {
  selectDealFuneralDomain,
  makeSelectDeal,
  makeSelectLoading,
  makeSelectError,
  makeSelectStepDoneByOtherFuneral,
  makeSelectManualSelection,
  makeSelectFuneral,
};
