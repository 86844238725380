import { defineMessages } from 'react-intl';

export default defineMessages({
  cash: { id: 'app.containers.Deal.Payments.Payment.cash' },
  check: { id: 'app.containers.Deal.Payments.Payment.check' },
  transfer: { id: 'app.containers.Deal.Payments.Payment.transfer' },
  'defunct-account': {
    id: 'app.containers.Deal.Payments.Payment.defunct-account',
  },
  card: { id: 'app.containers.Deal.Payments.Payment.card' },
  withdrawal: { id: 'app.containers.Deal.Payments.Payment.withdrawal' },
  waiting: { id: 'app.containers.Deal.Payments.Payment.waiting' },
  error: { id: 'app.containers.Deal.Payments.Payment.error' },
  accepted: { id: 'app.containers.Deal.Payments.Payment.accepted' },
  cancel: { id: 'app.containers.Deal.Payments.Payment.cancel' },
  reimbursed: { id: 'app.containers.Deal.Payments.Payment.reimbursed' },
  guarantee: { id: 'app.containers.Deal.Payments.Payment.guarantee' },
  paid: { id: 'app.containers.Deal.Payments.Payment.paid' },
  CreditCard: { id: 'app.containers.Deal.Payments.Payment.CreditCard' },
  SepaTransfer: { id: 'app.containers.Deal.Payments.Payment.SepaTransfer' },
  FACILYPAY3X: { id: 'app.containers.Deal.Payments.Payment.FACILYPAY3X' },
  FACILYPAY4X: { id: 'app.containers.Deal.Payments.Payment.FACILYPAY4X' },
  ThreeTimes: { id: 'app.containers.Deal.Payments.Payment.ThreeTimes' },
  FourTimes: { id: 'app.containers.Deal.Payments.Payment.FourTimes' },
  TenTimes: { id: 'app.containers.Deal.Payments.Payment.TenTimes' },
  TwelveTimes: { id: 'app.containers.Deal.Payments.Payment.TwelveTimes' },
  Deferred: { id: 'app.containers.Deal.Payments.Payment.Deferred' },
  'funeral-contract': {
    id: 'app.containers.Deal.Payments.Payment.funeral-contract',
  },
});
