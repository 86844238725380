import { Tokens, updateTokens } from '@advitam/api/lib/tokens'
import { SearchParams } from '@advitam/support'

interface TokenParams {
  auth_token: string
  blank: string
  client_id: string
  config: string
  expiry: string
  uid: string
}

const KEYS: ReadonlyArray<keyof TokenParams> = [
  'auth_token',
  'blank',
  'client_id',
  'config',
  'expiry',
  'uid',
]

export function checkOmniAuthTokens(): boolean {
  const searchParams = SearchParams.getObject<TokenParams>(KEYS)
  if (!searchParams) {
    return false
  }

  const tokens: Tokens = {
    client: searchParams.client_id,
    'access-token': searchParams.auth_token,
    'token-type': 'Bearer',
    uid: searchParams.uid,
    expiry: searchParams.expiry,
  }
  updateTokens(tokens)

  const params = new URLSearchParams(window.location.search)
  KEYS.forEach(key => {
    params.delete(key)
  })
  window.history.replaceState({}, '', window.location.pathname + params.toString())

  return true
}
