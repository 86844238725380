import { Model } from '../Model'

export interface RateBase {
  id: number
  weight: number
  cost: number
  price: number
  supplier_warehouse_id: number
  supplier_name: string
  disabled_supplier: boolean
  deposit_days: number
}

export interface RateJSON extends RateBase {
  updated_at: string
}

export interface Rate extends RateBase {
  updated_at: Date
}

export class Rate extends Model<RateJSON> {
  constructor(data: RateJSON) {
    super(data)
    this.updated_at = Model.parseDate(data.updated_at)
  }
}
