import { FormattedMessage } from 'react-intl';

import { DealCommunication } from '@advitam/api/models/Deal/Communication';
import { CommunicationState } from '@advitam/api/models/Deal/Communication/State';
import { FormattedDate, Tooltip } from '@advitam/ui';
import Check from '@advitam/ui/images/icons/check.svg';
import CheckDouble from '@advitam/ui/images/icons/check-double.svg';
import Cross from '@advitam/ui/images/icons/times.svg';
import Clock from '@advitam/ui/images/icons/later.svg';
import Missed from '@advitam/ui/images/icons/missed.svg';
import Voicemail from '@advitam/ui/images/icons/voicemail.svg';
import Transfered from '@advitam/ui/images/icons/transfer.svg';
import Outgoing from '@advitam/ui/images/icons/arrow-up-right.svg';
import Incoming from '@advitam/ui/images/icons/arrow-down-right.svg';

import { CommunicationType } from '@advitam/api/models/Deal/Communication/Type';
import messages from '../messages';
import style from './Row.module.scss';

interface StatusProps {
  communication: DealCommunication;
}

export default function Status({
  communication,
}: StatusProps): JSX.Element | null {
  switch (communication.state) {
    case CommunicationState.SENT:
      return (
        <Tooltip content={<FormattedMessage id={messages.sent.id} />}>
          <Check className={[style.status, style.sent].join(' ')} />
        </Tooltip>
      );
    case CommunicationState.RECEIVED:
      if (communication.type === CommunicationType.INCOMING) {
        return (
          <Tooltip content={<FormattedMessage id={messages.incoming.id} />}>
            <Incoming className={[style.status, style.incoming].join(' ')} />
          </Tooltip>
        );
      }
      return (
        <Tooltip
          content={
            <FormattedMessage
              id={messages.received.id}
              values={{
                date: communication.received_at && (
                  <FormattedDate value={communication.received_at} />
                ),
              }}
            />
          }
        >
          <CheckDouble className={[style.status, style.received].join(' ')} />
        </Tooltip>
      );
    case CommunicationState.FAILED:
      return (
        <Tooltip
          content={
            <>
              <FormattedMessage id={messages.failed.id} />
              {communication.error && (
                <>
                  <br />
                  {communication.error}
                </>
              )}
            </>
          }
        >
          <Cross className={[style.status, style.failed].join(' ')} />
        </Tooltip>
      );
    case CommunicationState.PENDING:
    case CommunicationState.DISPATCHED:
      return (
        <Tooltip content={<FormattedMessage id={messages.pending.id} />}>
          <Clock className={[style.status, style.pending].join(' ')} />
        </Tooltip>
      );
    case CommunicationState.MISSED:
      return (
        <Tooltip content={<FormattedMessage id={messages.missed.id} />}>
          <Missed className={[style.status, style.missed].join(' ')} />
        </Tooltip>
      );
    case CommunicationState.VOICEMAIL:
      return (
        <Tooltip content={<FormattedMessage id={messages.voicemail.id} />}>
          <Voicemail className={[style.status, style.voicemail].join(' ')} />
        </Tooltip>
      );
    case CommunicationState.BLIND_TRANSFERED:
      return (
        <Tooltip content={<FormattedMessage id={messages.transfered.id} />}>
          <Transfered className={[style.status, style.transfered].join(' ')} />
        </Tooltip>
      );
    case CommunicationState.ANSWERED:
      if (communication.type === CommunicationType.INCOMING) {
        return (
          <Tooltip content={<FormattedMessage id={messages.incoming.id} />}>
            <Incoming className={[style.status, style.incoming].join(' ')} />
          </Tooltip>
        );
      }
      return (
        <Tooltip content={<FormattedMessage id={messages.outgoing.id} />}>
          <Outgoing className={[style.status, style.outgoing].join(' ')} />
        </Tooltip>
      );
    case CommunicationState.NOTHING:
    default:
      return null;
  }
}
