import { createSelector, Selector } from 'reselect';

import { InsolvencyProceedingsStatus } from '@advitam/api/models/Supplier';

import { makeSelectSupplier } from '../../selectors';
import type { AppStateSubset } from '../../slice';

type SupplierSelectors<T> = Selector<AppStateSubset, T>;

export function makeSelectIsSupplierForceDisabled(): SupplierSelectors<
  boolean
> {
  return createSelector(makeSelectSupplier(), supplier =>
    Boolean(
      supplier?.activity_ceased ||
        supplier?.insolvency_proceedings_status ===
          InsolvencyProceedingsStatus.LIQUIDATION,
    ),
  );
}
