import { createSelector } from 'reselect';
import moment from 'moment';

import {
  makeSelectDeal,
  makeSelectProducts,
  makeSelectSteps,
} from 'containers/Deal/selectors';
import { selectDealFuneralDomain } from '../selectors';

const makeSelectStep = () =>
  createSelector(makeSelectSteps(), substate => id => substate[id]);

const makeSelectConcessionPrices = () =>
  createSelector(
    selectDealFuneralDomain,
    substate => substate.concessionPrices,
  );

const makeSelectDefunctInfo = () =>
  createSelector(makeSelectDeal(), substate =>
    substate ? substate.defunctInfo : null,
  );

const makeSelectDefunctPerson = () =>
  createSelector(makeSelectDefunctInfo(), substate =>
    substate ? substate.person : null,
  );

const makeSelectDefunctBirthDate = () =>
  createSelector(makeSelectDefunctPerson(), substate =>
    substate ? substate.birthDate : null,
  );

const makeSelectDefunctAge = () =>
  createSelector(
    makeSelectDefunctBirthDate(),
    makeSelectSteps(),
    (birthDate, steps) => {
      const deathStep = steps && steps.find(step => step.eventType === 'death');
      // We need to return undefined for qs to omit parameters
      if (!steps || !birthDate || !deathStep) return undefined;

      const deathDate = deathStep.eventDate;
      if (!deathDate) return undefined;

      const birth = moment.unix(birthDate);
      const death = moment.unix(deathDate);
      const years = moment.duration(death - birth).asYears();
      // years is fractional, you don't want 50.73971 years old to be displayed
      return Math.floor(years);
    },
  );

const makeSelectCoffin = () =>
  createSelector(makeSelectProducts(), substate => {
    if (!substate) return null;
    return substate.find(item => item.type === 'Coffin');
  });

const makeSelectCrematorium = () =>
  createSelector(selectDealFuneralDomain, substate => substate.crematorium);

const makeSelectCeremonies = () =>
  createSelector(selectDealFuneralDomain, substate => substate.ceremonies);

const makeSelectConcessionTypes = () =>
  createSelector(selectDealFuneralDomain, substate => substate.concessionTypes);

const makeSelectFuneralParlorStayTypes = () =>
  createSelector(
    selectDealFuneralDomain,
    substate => substate.funeralParlorStayTypes,
  );

export {
  makeSelectStep,
  makeSelectConcessionPrices,
  makeSelectCoffin,
  makeSelectCrematorium,
  makeSelectCeremonies,
  makeSelectDefunctAge,
  makeSelectConcessionTypes,
  makeSelectFuneralParlorStayTypes,
  makeSelectDefunctInfo,
};
