import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Role } from '@advitam/api/models/BusinessUser/Role';
import { assert } from '@advitam/support';
import { FormLayout, TextArea } from '@advitam/ui';
import { AdministrationActions, Layout } from 'containers/Crud';

import { AirlineAutocomplete, AirportAutocomplete } from '../../Form';
import { destroyFlight, setIsFlightDisabled } from '../../thunk';
import messages from '../../messages';
import {
  makeSelectDestroyError,
  makeSelectIsDestroying,
  makeSelectRawFlight,
} from '../../selectors';
import Wrapper from '../Wrapper';
import { makeSelectCanEdit } from './selectors';

export default function General(): JSX.Element {
  const dispatch = useDispatch();

  const flight = useSelector(makeSelectRawFlight());
  assert(flight !== null);
  const canEditAdmin = useSelector(makeSelectCanEdit(Role.ADMIN));
  const canEditFuneralDirector = useSelector(
    makeSelectCanEdit(Role.FUNERAL_DIRECTOR),
  );
  const isDestroying = useSelector(makeSelectIsDestroying());
  const destroyError = useSelector(makeSelectDestroyError());

  const onDelete = useCallback(() => {
    dispatch(destroyFlight());
  }, [dispatch]);

  const setIsDisabled = useCallback(
    (value: boolean) => {
      dispatch(setIsFlightDisabled(value));
    },
    [dispatch],
  );

  return (
    <Wrapper>
      <Layout.Container>
        {flight.id && (
          <AdministrationActions
            isDisabled={flight.disabled}
            setIsDisabled={setIsDisabled}
            entityName={flight.auto_name}
            onDelete={onDelete}
            isDeletionLoading={isDestroying}
            destroyError={destroyError}
            errorMessages={messages}
          />
        )}
        <Layout.ColumnGroup>
          <Layout.Column>
            <Layout.Fieldset
              title={<FormattedMessage id={messages.commentary.id} />}
            >
              <TextArea name="comment" />
            </Layout.Fieldset>
          </Layout.Column>
        </Layout.ColumnGroup>
        <Layout.ColumnGroup>
          <Layout.Column>
            <Layout.Fieldset
              title={<FormattedMessage id={messages.informations.id} />}
            >
              <FormLayout.Row>
                <AirlineAutocomplete
                  name="airline"
                  label={<FormattedMessage id={messages.airline.id} />}
                  disabled={!canEditAdmin}
                />
              </FormLayout.Row>
              <FormLayout.Row>
                <AirportAutocomplete
                  name="departure_airport"
                  label={<FormattedMessage id={messages.departureAirport.id} />}
                  required
                  disabled={!canEditAdmin}
                />
              </FormLayout.Row>
              <FormLayout.Row>
                <AirportAutocomplete
                  name="stopover_airport"
                  label={<FormattedMessage id={messages.stopoverAirport.id} />}
                  disabled={!canEditFuneralDirector}
                />
              </FormLayout.Row>
              <FormLayout.Row>
                <AirportAutocomplete
                  name="arrival_airport"
                  label={<FormattedMessage id={messages.arrivalAirport.id} />}
                  required
                  disabled={!canEditAdmin}
                />
              </FormLayout.Row>
            </Layout.Fieldset>
          </Layout.Column>
          <Layout.Column />
        </Layout.ColumnGroup>
      </Layout.Container>
    </Wrapper>
  );
}
