import { useCallback } from 'react';
import { Field, FieldRenderProps, useForm } from 'react-final-form';

import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import type { RateJSON } from '@advitam/api/models/Flight/Rate';
import type { AutocompleteResult } from '@advitam/ui/components/Form/UI/Autosuggest/types';
import { FormUI, isRequired } from '@advitam/ui';
import { useFormValue } from '@advitam/react';

function getDisplayValue(item: RateJSON): string {
  return item.supplier_name || '';
}

interface SupplierAutocompleteResult extends AutocompleteResult {
  supplier_name: string;
}

interface SupplierAutocompleteProps {
  prefix: string;
  disabled?: boolean;
  className?: string;
}

export default function SupplierAutocomplete({
  prefix,
  disabled,
  className,
}: SupplierAutocompleteProps): JSX.Element {
  const form = useForm();
  const departureAirportId = useFormValue<number>('departure_airport.id');
  const rate = useFormValue<RateJSON>(prefix);

  const onChange = useCallback(
    (r: SupplierAutocompleteResult | undefined): void => {
      form.batch(() => {
        form.change(`${prefix}.supplier_warehouse_id`, r?.id || null);
        form.change(`${prefix}.supplier_name`, r?.supplier_name || null);
      });
    },
    [form, prefix],
  );

  const render = useCallback(
    ({ meta }: FieldRenderProps<RateJSON>) => (
      <FormUI.Autosuggest<RateJSON & AutocompleteResult>
        endpoint={Api.V1.absolute(
          Api.V1.Autocompletes.Path.airports.warehouses(departureAirportId),
        )}
        value={rate as RateJSON & AutocompleteResult}
        getDisplayValue={getDisplayValue}
        requestHeaders={{ ...getTokens() }}
        onChange={onChange}
        error={meta.touched && !meta.valid}
        disabled={disabled || !departureAirportId}
        className={className}
      />
    ),
    [className, disabled, departureAirportId, onChange, rate],
  );

  return (
    <div>
      <Field
        name={`${prefix}.supplier_warehouse_id`}
        validate={isRequired}
        render={render}
      />
    </div>
  );
}
