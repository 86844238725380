import { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { RepatriationType } from '@advitam/api/models/Repatriation/Type';
import { TextInput } from 'components/Input/index.js';
import { assert } from '@advitam/support';

import { TRIP_CONTACT_SKELETON } from '../constants';
import messages from '../messages';
import {
  RepatriationStep,
  RepatriationTrip,
  RepatriationTripContact,
  RepatriationTripFlight,
} from '../types';
import TripContact from './Contact';
import TripCrossedBorders from './CrossedBorders';
import TripFlights from './Flights';

const DEFAULT_FLIGHTS: RepatriationTripFlight[] = [];
const DEFAULT_CROSSED_BORDERS = [''];

interface TripProps {
  step: RepatriationStep;
  handleChange: (value: Partial<RepatriationStep>) => void;
}

export default function Trip({ handleChange, step }: TripProps): JSX.Element {
  assert(step.trip !== null);

  const { trip, repatriation_type: type } = step;
  const contact = trip.contact || TRIP_CONTACT_SKELETON;
  const flights = trip.flights || DEFAULT_FLIGHTS;
  const isPlaneRepatriation = type === RepatriationType.PLANE;

  const onTripChange = (value: Partial<RepatriationTrip>): void => {
    handleChange({
      trip: {
        ...trip,
        ...value,
      },
    });
  };

  return (
    <div className="trip__wrapper">
      <div>
        {isPlaneRepatriation && (
          <div className="trip__wrapper__field">
            <FormattedMessage id={messages.LTAnumber.id} />
            <TextInput
              value={trip.lta || ''}
              onChange={({
                target: { value },
              }: ChangeEvent<HTMLInputElement>): void =>
                onTripChange({ lta: value })
              }
            />
          </div>
        )}
        <div className="trip__wrapper__field margin--10-0-0-0">
          <FormattedMessage id={messages.intermentPlace.id} />
          <TextInput
            value={trip.interment_place || ''}
            onChange={({
              target: { value },
            }: ChangeEvent<HTMLInputElement>): void =>
              onTripChange({ interment_place: value })
            }
          />
        </div>
        <TripContact
          isCompany={contact.isCompany}
          infoCivilian={contact.infoCivilian}
          infoCompany={contact.infoCompany}
          handleChange={(update: Partial<RepatriationTripContact>): void =>
            onTripChange({
              contact: { ...contact, ...update },
            })
          }
        />
      </div>
      {isPlaneRepatriation && (
        <TripFlights flights={flights} onTripChange={onTripChange} />
      )}
      {!isPlaneRepatriation && (
        <TripCrossedBorders
          crossedBorders={
            trip.crossed_borders.length > 0
              ? trip.crossed_borders
              : DEFAULT_CROSSED_BORDERS
          }
          onTripChange={onTripChange}
        />
      )}
    </div>
  );
}
