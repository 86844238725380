import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Api from '@advitam/api';
import { getTokens } from '@advitam/api/lib/tokens';
import type { AutocompleteAirportResult } from '@advitam/api/v1/Autocompletes';
import { withSlice } from '@advitam/react';
import { FormLayout, FormUI } from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';

import type { RepatriationStep } from '../types';
import { TRIP_SKELETON } from '../constants';
import messages from '../messages';
import slice from './planeSlice';
import { makeSelectAirlines, makeSelectAirports } from './planeSlice/selectors';
import { fetchFlights } from './planeSlice/thunk';

interface PlaneProps {
  step: RepatriationStep;
  onChange: (value: Partial<RepatriationStep>) => void;
}

function Plane({ step, onChange }: PlaneProps): JSX.Element {
  const dispatch = useDispatch();

  const departureAirports = useSelector(makeSelectAirports());
  const airlines = useSelector(makeSelectAirlines());

  useEffect(() => {
    if (!step.destination?.countryCode) {
      return;
    }

    dispatch(fetchFlights());
  }, [dispatch, step.destination?.countryCode]);

  const onArrivalChange = useCallback(
    (value: AutocompleteAirportResult | undefined): void => {
      onChange({
        location: null,
        departure_airport: null,
        destination: {
          countryCode: value?.country_code || null,
          country: value?.country_name || null,
          country_id: value?.country_id || null,
          city: value?.city || null,
        },
        trip: {
          ...(step.trip || TRIP_SKELETON),
          air_company: null,
        },
      });
    },
    [onChange, step],
  );

  const onDepartureChange = useCallback(
    (value: number | number[] | undefined): void => {
      const airport = departureAirports.find(a => a.id === value);
      onChange({ location: null, departure_airport: airport });
    },
    [onChange, departureAirports],
  );

  const onAirlineChange = useCallback(
    (value: number | number[] | undefined): void => {
      const airline = airlines.find(a => a.id === value);
      onChange({
        location: null,
        trip: {
          ...(step.trip || TRIP_SKELETON),
          air_company: airline?.name || null,
          airline_id: airline?.id || null,
        },
      });
    },
    [onChange, step.trip, airlines],
  );

  const arrivalAirport = useMemo((): AutocompleteAirportResult | undefined => {
    if (!step.destination?.countryCode) {
      return undefined;
    }

    return {
      id: 0,
      description: `${step.destination.city} - ${step.destination.country}`,
      name: '',
      iata: '',
      country_name: step.destination.country || '',
      country_code: step.destination.countryCode,
      country_id: step.destination.country_id || 0,
      city: step.destination.city || '',
    };
  }, [step.destination]);

  const departureAirportItems = useMemo(
    (): SelectableItem<number>[] =>
      departureAirports.map(airport => ({
        value: airport.id,
        name: `${airport.iata} - ${airport.city} - ${airport.country_name}`,
      })),
    [departureAirports],
  );

  const airlineItems = useMemo(
    (): SelectableItem<number>[] =>
      airlines.map(a => ({
        value: a.id,
        name: a.name,
      })),
    [airlines],
  );

  return (
    <>
      <FormLayout.Row>
        <FormUI.Autosuggest
          label={<FormattedMessage id={messages.arrival.id} />}
          endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.airports.index)}
          value={arrivalAirport}
          onChange={onArrivalChange}
          requestHeaders={{ ...getTokens() }}
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <FormUI.Select<number>
          items={departureAirportItems}
          label={<FormattedMessage id={messages.departure.id} />}
          placeholder={<FormattedMessage id={messages.autoselected.id} />}
          value={step.departure_airport?.id || undefined}
          onChange={onDepartureChange}
          disabled={!arrivalAirport}
          filter
          unselectable
        />
      </FormLayout.Row>
      <FormLayout.Row>
        <FormUI.Select<number>
          items={airlineItems}
          label={<FormattedMessage id={messages.airCompany.id} />}
          placeholder={<FormattedMessage id={messages.autoselected.id} />}
          value={step.trip?.airline_id || undefined}
          onChange={onAirlineChange}
          disabled={!arrivalAirport}
          filter
          unselectable
        />
      </FormLayout.Row>
    </>
  );
}

export default withSlice<PlaneProps>(slice)(Plane);
