import { IntlShape, useIntl } from 'react-intl'

import { Civility, FormatType, Nameable } from './types'
import messages from './messages'

export function formatCivility(civility: Civility, intl: IntlShape): string {
  if (!messages[civility]) {
    return ''
  }
  return intl.formatMessage(messages[civility])
}

function capitalizeFirstLetter(value: string | null): string {
  if (!value) {
    return ''
  }
  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`
}

export function formatFullname(person: Nameable, format = FormatType.STANDARD): string {
  let firstname = capitalizeFirstLetter(person.firstname)
  let lastname = capitalizeFirstLetter(person.lastname)

  if (format === FormatType.FORMAL) {
    firstname = ''
  }

  if (format === FormatType.CAPITALIZED_LASTNAME) {
    lastname = lastname.toLocaleUpperCase()
  }

  if (format === FormatType.ANONIMIZED) {
    lastname = `${lastname[0]}.`
  }

  return [firstname, lastname].join(' ')
}

export function formatName(
  person: Nameable,
  intl: IntlShape,
  format = FormatType.STANDARD,
): string {
  return [person.civility && formatCivility(person.civility, intl), formatFullname(person, format)]
    .filter(part => part && part !== '')
    .join(' ')
}

interface FormattedNameProps {
  person: Nameable
  format?: FormatType
}

export function FormattedName({ person, format }: FormattedNameProps): JSX.Element {
  const intl = useIntl()
  return <>{formatName(person, intl, format)}</>
}
