import { PaymentState } from '@advitam/api/models/Payment/State';
import { Role } from '@advitam/api/models/BusinessUser/Role';

import { BookingStatus } from 'models/BookingStatus';
import { isUserFuneralDirectorOrAbove } from 'components/Authorization/utils.js';

import { FUNERAL_ADVISOR_ALLOWED_STATES } from './constants';

export function canUserEditPayment(
  paymentState: PaymentState,
  isNewPayment: boolean,
  bookingStatus: BookingStatus,
  userRole: Role,
): boolean {
  if (bookingStatus === BookingStatus.BOOKED) {
    return userRole === Role.ADMIN || isNewPayment;
  }

  return (
    isUserFuneralDirectorOrAbove(userRole) ||
    isNewPayment ||
    FUNERAL_ADVISOR_ALLOWED_STATES.includes(paymentState)
  );
}
