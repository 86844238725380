import { authenticate } from '../lib/decorators'
import { post } from '../lib/methods'
import type { ApiRequestDescriptor } from '../lib/request'

export enum ServiceType {
  PERKS = 'perks',
  PAPERWORKS = 'paperworks',
}

export interface ServiceSuggestionPayload {
  name: string
  description: string
  type: ServiceType
}

export const ServiceSuggestions = {
  create: (payload: ServiceSuggestionPayload): ApiRequestDescriptor<void> =>
    authenticate(post('/api/v1/service_suggestions', payload)),
}
