import { FormattedMessage } from 'react-intl'
import { Company, I18nPath, Path } from '../../constants'
import Logo from '../../images/logo.svg'
import MobileLogo from '../../images/mobile_logo.svg'
import UserIcon from '../../images/icons/user.svg'
import Menu, {
  MenuDropdown,
  MenuItem,
  MenuLink,
  MenuLinkButton,
  MyAccountMenuIcon,
  MenuLogo,
  MenuPhoneLink,
} from './Menu'
import { menuLinks } from './constants'
import messages from './messages'

interface NavigationProps {
  internalLinks?: boolean
  internalAccount?: boolean
  white?: boolean
  locale: keyof typeof I18nPath
  withoutQuote?: boolean
}

export default function Navigation({
  white = false,
  internalLinks = true,
  internalAccount = false,
  withoutQuote = false,
  locale,
}: NavigationProps): JSX.Element {
  const logo: MenuLogo = {
    href: Path.HOME,
    image: <Logo />,
    mobileImage: <MobileLogo />,
    internal: internalLinks,
  }
  const leftSectionItems = menuLinks[locale] || menuLinks.en
  const leftSection: MenuItem[] = leftSectionItems.map(item => {
    if (!item.items) {
      return {
        displayOnDesktop: true,
        children: (
          <MenuLink
            text={<FormattedMessage id={item.nameKey} />}
            href={item.href}
            internal={item.internal && internalLinks}
          />
        ),
      }
    }

    return {
      displayOnDesktop: true,
      children: (
        <MenuDropdown
          placeholder={<FormattedMessage id={item.nameKey} />}
          items={item.items.map(subItem => ({
            href: subItem.href,
            text: <FormattedMessage id={subItem.nameKey} />,
            subtext: subItem.subtextKey && <FormattedMessage id={subItem.subtextKey} />,
            internal: subItem.internal && internalLinks,
          }))}
        />
      ),
    }
  })

  const rightSection: MenuItem[] = [
    {
      children: (
        <MenuLink
          text={<FormattedMessage id={messages.login.id} />}
          href={I18nPath[locale]?.MY_ACCOUNT || I18nPath.en.MY_ACCOUNT}
          internal={internalAccount}
        />
      ),
    },
    {
      displayOnDesktop: true,
      displayOnTablet: true,
      children: (
        <MenuPhoneLink
          number={Company.PHONE_NUMBER}
          formattedNumber={Company.FORMATTED_PHONE_NUMBER}
          text={<FormattedMessage id={messages.needHelp.id} />}
        />
      ),
    },
    withoutQuote
      ? null
      : {
          displayOnDesktop: true,
          displayOnTablet: true,
          children: (
            <MenuLinkButton
              text={<FormattedMessage id={messages.requestQuote.id} />}
              href={I18nPath[locale]?.FUNERALS_QUOTE || I18nPath.en.FUNERALS_QUOTE}
              internal={internalLinks}
            />
          ),
        },
    {
      displayOnDesktop: true,
      hideInSidebar: true,
      children: (
        <MyAccountMenuIcon
          icon={<UserIcon />}
          href={I18nPath[locale]?.MY_ACCOUNT || I18nPath.fr.MY_ACCOUNT}
          internal={internalAccount}
          tooltip={<FormattedMessage id={messages.myPersonalSpace.id} />}
        />
      ),
    },
  ].filter(Boolean) as MenuItem[]

  return <Menu logo={logo} leftSection={leftSection} rightSection={rightSection} white={white} />
}

export { Menu as NavigationBase }
export * from './Menu'
