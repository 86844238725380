import { authenticate } from '../../../lib/decorators'
import { get } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import type { SupplierCoverageJSON } from '../../../models/Supplier/Coverage'
import { Synchronize } from './Synchronize'

export const Coverage = {
  Synchronize,

  index: (supplierIds?: number[]): ApiRequestDescriptor<SupplierCoverageJSON[]> =>
    authenticate(get('/api/v1/suppliers/coverage', { suppliers: supplierIds })),
}
