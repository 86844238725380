import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import { Flight, FlightJSON } from '@advitam/api/models/Flight';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';

import { FLIGHT } from './constants';
import type { AppStateSubset, State } from './slice';
import type { NewFlight } from './types';

type FlightSelector<T> = Selector<AppStateSubset, T>;

function selectFlightDomain(state: AppStateSubset): State {
  return state[FLIGHT];
}

export function makeSelectRawFlight(): FlightSelector<
  FlightJSON | NewFlight | null
> {
  return createSelector(selectFlightDomain, ({ flight }) => flight);
}

export function makeSelectFlight(): FlightSelector<Flight | null> {
  return createSelector(makeSelectRawFlight(), rawFlight =>
    rawFlight?.id ? new Flight(rawFlight) : null,
  );
}

export function makeSelectRateSuppliersFilterItems(): FlightSelector<
  SelectableItem[]
> {
  return createSelector(makeSelectFlight(), flight => {
    if (!flight) {
      return [];
    }

    return flight.rates
      .map(rate => ({
        name: rate.supplier_name,
        value: rate.supplier_warehouse_id,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  });
}

export function makeSelectIsLoading(): FlightSelector<boolean> {
  return createSelector(selectFlightDomain, ({ isLoading }) => isLoading);
}

export function makeSelectIsSaving(): FlightSelector<boolean> {
  return createSelector(selectFlightDomain, ({ isSaving }) => isSaving);
}

export function makeSelectIsDestroying(): FlightSelector<boolean> {
  return createSelector(selectFlightDomain, ({ isDestroying }) => isDestroying);
}

export function makeSelectError(): FlightSelector<SerializedApiError | null> {
  return createSelector(selectFlightDomain, ({ error }) => error);
}

export function makeSelectDestroyError(): FlightSelector<SerializedApiError | null> {
  return createSelector(selectFlightDomain, ({ destroyError }) => destroyError);
}
