export function uniqBy<T, U>(array: readonly T[], iteratee: (v: T) => U): T[] {
  const seen = new Set<U>()

  return array.filter(element => {
    const key = iteratee(element)
    const isNew = !seen.has(key)
    seen.add(key)
    return isNew
  })
}
