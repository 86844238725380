import { ContactMedia } from '@advitam/api/models/Entity/ContactMedia';
import type { NewSupplierWarehouse } from './types';

export const SUPPLIER_WAREHOUSE = 'SupplierWarehouse';

export const Path = {
  ZONES: 'zones',
  GENERAL: '',
  MISCELLANEOUS: 'divers',
  PRODUCTS: 'produits',
};

export const WAREHOUSE_SKELETON: NewSupplierWarehouse = {
  name: '',
  address: null,
  address_l2: null,
  postal_code: null,
  insee_code: null,
  city: null,
  department: null,
  country: null,
  latitude: null,
  longitude: null,
  manual_address: false,
  phone: null,
  phone_2: null,
  phone_3: null,
  fax: null,
  email: null,
  email_2: null,
  email_3: null,
  email_4: null,
  comment: null,
  email_feedback: null,
  contact_phone: null,
  prebooking_enabled: true,
  prebooking_prefered_contact_media: ContactMedia.EMAIL,
  booking_enabled: true,
  booking_prefered_contact_media: ContactMedia.EMAIL,
  price_request: false,
  price_request_prefered_contact_media: ContactMedia.EMAIL,
  accept_coffin_delivery: false,
  has_delivery_fees: true,
  advitam_grade: 5,
  airport: null,
  disabled: false,
  last_update_user_id: null,
  last_update_dt: null,
  current_update_user_id: null,
  current_update_dt: null,
};
