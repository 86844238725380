import PropTypes from 'prop-types';

import Prestations from './Prestations';
import SelectSolution from './SelectSolution';

export default function Solution({
  solution,
  groupName,
  checkDependenciesAndUdpate,
}) {
  return (
    <div className="booking__prestation">
      <div className="booking__prestation__selection--prestations booking__prestation__selection--cell">
        <Prestations groupName={groupName} prestations={solution.prestations} />
      </div>
      <div className="booking__prestation__selection__choices booking__prestation__selection--choices booking__prestation__selection--cell">
        <SelectSolution
          groupName={groupName}
          solutions={solution.solutions}
          checkDependenciesAndUdpate={checkDependenciesAndUdpate}
        />
      </div>
    </div>
  );
}

Solution.propTypes = {
  solution: PropTypes.object.isRequired,
  checkDependenciesAndUdpate: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
};
