import type { ComponentType } from 'react'

import { checkOmniAuthTokens } from './utils'

export default function withOmniauth<Props extends JSX.IntrinsicAttributes>(
  Component: ComponentType<Props>,
): ComponentType<Props> {
  return function WithOmniauth(props: Props): JSX.Element {
    checkOmniAuthTokens()

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} />
  }
}
