import { ReactNode, useCallback } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'

import { NullableString } from '../converters'
import TextAreaUI from '../UI/TextArea'
import { isRequired, useValidators, Validator } from '../validators'

interface TextAreaProps {
  name: string
  label?: ReactNode
  placeholder?: string
  disabled?: boolean
  required?: boolean
  maxLength?: number
  renderBelow?: (value: string) => ReactNode
  validate?: Validator<string | null>
  className?: string
  tooltip?: ReactNode
}

export default function TextArea({
  name,
  label,
  placeholder,
  disabled,
  required,
  maxLength,
  renderBelow,
  validate,
  className,
  tooltip,
}: TextAreaProps): JSX.Element {
  const validator = useValidators<string | null>(required && isRequired, validate)

  const render = useCallback(
    (props: FieldRenderProps<string | null>): JSX.Element => (
      <TextAreaUI
        label={label}
        onChange={props.input.onChange}
        onBlur={props.input.onBlur}
        placeholder={placeholder}
        disabled={disabled}
        value={props.input.value}
        maxLength={maxLength}
        renderBelow={renderBelow}
        error={props.meta.touched && !props.meta.valid}
        className={className}
        tooltip={tooltip}
      />
    ),
    [label, placeholder, disabled, maxLength, renderBelow, className, tooltip],
  )

  return (
    <Field
      name={name}
      validate={validator}
      parse={NullableString.parse}
      format={NullableString.format}
      formatOnBlur
      render={render}
    />
  )
}
