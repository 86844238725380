import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  category: { id: 'app.containers.Client.sections.Resources.category' },
  item: { id: 'app.containers.Client.sections.Resources.item' },
  funerals: { id: 'app.containers.Client.sections.Resources.funerals' },
  marble: { id: 'app.containers.Client.sections.Resources.marble' },
  memorial: { id: 'app.containers.Client.sections.Resources.memorial' },
  administrativeProcedure: {
    id: 'app.containers.Client.sections.Resources.administrativeProcedure',
  },
  safeKeeper: { id: 'app.containers.Client.sections.Resources.safeKeeper' },
  funeralWills: { id: 'app.containers.Client.sections.Resources.funeralWills' },
  search: { id: 'app.containers.Client.sections.Resources.search' },
  id: { id: 'app.containers.Client.sections.Resources.id' },
  businessId: { id: 'app.containers.Client.sections.Resources.businessId' },
  owner: { id: 'app.containers.Client.sections.Resources.owner' },
  status: { id: 'app.containers.Client.sections.Resources.status' },
  ownerRole: { id: 'app.containers.Client.sections.Resources.ownerRole' },
  editor: { id: 'app.containers.Client.sections.Resources.editor' },
  reader: { id: 'app.containers.Client.sections.Resources.reader' },
  notary: { id: 'app.containers.Client.sections.Resources.notary' },
  actions: { id: 'app.containers.Client.sections.Resources.actions' },
  view: { id: 'app.containers.Client.sections.Resources.view' },
  anonymized: { id: 'app.containers.Client.sections.Resources.anonymized' },
});
