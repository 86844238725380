import { useCallback, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Model } from '@advitam/api/models/Model';
import { withSlice } from '@advitam/react';
import { assert } from '@advitam/support';
import { ConfirmationModal, ResourceList } from '@advitam/ui';

import { makeSelectIsLoading } from '../../selectors';
import messages from '../../messages';
import type { FlightForm } from '../types';
import Filters from './Filters';
import Header from './Header';
import Row from './Row';
import type { RateForm } from './types';
import slice from './slice';
import { isFilteredOut } from './utils';
import { makeSelectFilters } from './selectors';

function doNothing(): void {
  // As the name suggests
}

function List(): JSX.Element {
  const [indexOfRateToDelete, setIndexOfRateToDelete] = useState<number | null>(
    null,
  );

  const isLoading = useSelector(makeSelectIsLoading());
  const filters = useSelector(makeSelectFilters());

  const form = useForm<FlightForm & RateForm>();
  const { values } = useFormState<FlightForm>();
  const { rates } = values;

  const onDeletionConfirmed = useCallback(() => {
    assert(indexOfRateToDelete !== null);
    if (rates[indexOfRateToDelete].id) {
      const rate = { ...rates[indexOfRateToDelete] };
      Model.setDestroyed(rate);
      form.change(`rates[${indexOfRateToDelete}]`, rate);
    } else {
      const updatedRates = [...rates];
      updatedRates.splice(indexOfRateToDelete, 1);
      form.change('rates', updatedRates);
    }
    setIndexOfRateToDelete(null);
  }, [form, rates, indexOfRateToDelete, setIndexOfRateToDelete]);

  const closeDeletionConfirmation = useCallback(() => {
    setIndexOfRateToDelete(null);
  }, [setIndexOfRateToDelete]);

  const render = useCallback(
    (): JSX.Element => (
      <>
        {rates.map((rate, index) =>
          isFilteredOut(rate, filters) ? null : (
            <Row
              // eslint-disable-next-line react/no-array-index-key
              key={`${rate.id || ''}-${index}`}
              prefix={`rates[${index}]`}
              rate={rate}
              onDelete={(): void => setIndexOfRateToDelete(index)}
            />
          ),
        )}
      </>
    ),
    [rates, setIndexOfRateToDelete, filters],
  );

  return (
    <>
      <ResourceList.Container
        filters={<Filters />}
        header={<Header />}
        fetchResources={doNothing}
        hasMore={false}
        isLoading={isLoading}
      >
        <Field name="rates" render={render} />
      </ResourceList.Container>

      {indexOfRateToDelete !== null && (
        <ConfirmationModal
          isOpen
          text={<FormattedMessage id={messages.deletionConfirmationText.id} />}
          confirm={
            <FormattedMessage id={messages.deletionConfirmationConfirm.id} />
          }
          onConfirm={onDeletionConfirmed}
          cancel={
            <FormattedMessage id={messages.deletionConfirmationCancel.id} />
          }
          onCancel={closeDeletionConfirmation}
        />
      )}
    </>
  );
}

export default withSlice(slice)(List);
