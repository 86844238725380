import { del, get, post, put } from '../../../lib/methods'
import type { ApiRequestDescriptor } from '../../../lib/request'
import { authenticate, withSessionId } from '../../../lib/decorators'
import type {
  SupplierMinimalWarehouseJSON,
  SupplierWarehouseJSON,
} from '../../../models/Supplier/Warehouse'
import { Duplicate } from './Duplicate'
import { Disabled } from './Disabled'
import { Products } from './Products'
import { Zones } from './Zones'
import type { WarehouseBody } from './types'
import { serialize } from './serializers'

export interface SupplierWarehousesIndexFilters {
  q?: string
  department_eq?: string
  disabled_eq?: boolean
  covered_cityhall_id?: number
  page?: number
  per_page?: number
}

export const Warehouses = {
  Disabled,
  Duplicate,
  Products,
  Zones,

  index: (
    supplierId: number,
    filters?: SupplierWarehousesIndexFilters,
  ): ApiRequestDescriptor<SupplierMinimalWarehouseJSON[]> =>
    authenticate(get(`/api/v1/suppliers/${supplierId}/warehouses`, filters)),

  show: (id: number): ApiRequestDescriptor<SupplierWarehouseJSON> =>
    authenticate(get(`/api/v1/suppliers/warehouses/${id}`)),

  create: (
    supplierId: number,
    warehouse: Omit<WarehouseBody, 'id'>,
  ): ApiRequestDescriptor<SupplierWarehouseJSON> =>
    authenticate(
      withSessionId(post(`/api/v1/suppliers/${supplierId}/warehouses`, serialize(warehouse))),
    ),

  update: (warehouse: WarehouseBody): ApiRequestDescriptor<SupplierWarehouseJSON> =>
    authenticate(
      withSessionId(put(`/api/v1/suppliers/warehouses/${warehouse.id}`, serialize(warehouse))),
    ),

  destroy: (id: number): ApiRequestDescriptor<void> =>
    authenticate(withSessionId(del(`/api/v1/suppliers/warehouses/${id}`))),
}
