import {
  SET_DEAL_SUMMARY,
  QUERY_UPDATE_DEAL_OWNER,
  SET_WORSHIP_TYPES,
  GET_CONCESSION_PRICES,
  SET_CONCESSION_PRICES,
  ERROR,
  GET_CREMATORIUM,
  SET_CREMATORIUM,
  GET_CEREMONIES,
  SET_CEREMONIES,
  GET_CONCESSION_TYPES,
  SET_CONCESSION_TYPES,
  GET_FUNERAL_PARLOR_STAY_TYPES,
  SET_FUNERAL_PARLOR_STAY_TYPES,
} from './constants';

export const initialState = {
  loading: false,
  error: null,
  worshipTypes: null,
  concessionPrices: null,
  dealSummary: null,
  invoices: {
    methods: [],
    payments: [],
  },
  crematorium: {},
  ceremonies: [],
  concessionTypes: [],
  funeralParlorStayTypes: [],
};

function dealFuneralReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FUNERAL_PARLOR_STAY_TYPES:
    case GET_CONCESSION_TYPES:
    case QUERY_UPDATE_DEAL_OWNER:
    case GET_CREMATORIUM:
    case GET_CEREMONIES:
    case GET_CONCESSION_PRICES:
      return {
        ...state,
        error: null,
      };
    case SET_CREMATORIUM:
      return {
        ...state,
        crematorium: action.crematorium,
      };
    case SET_CEREMONIES:
      return {
        ...state,
        ceremonies: action.ceremonies,
      };
    case SET_WORSHIP_TYPES:
      return {
        ...state,
        worshipTypes: action.worshipTypes,
      };
    case SET_CONCESSION_PRICES:
      return {
        ...state,
        concessionPrices: action.concessionPrices,
      };
    case SET_DEAL_SUMMARY:
      return {
        ...state,
        dealSummary: action.dealSummary,
      };
    case SET_CONCESSION_TYPES:
      return {
        ...state,
        concessionTypes: action.concessionTypes,
      };
    case SET_FUNERAL_PARLOR_STAY_TYPES:
      return {
        ...state,
        funeralParlorStayTypes: action.funeralParlorStayTypes,
      };
    case ERROR:
      return {
        ...state,
        error: action.err,
      };
    default:
      return state;
  }
}

export default dealFuneralReducer;
