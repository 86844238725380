import { useDispatch, useSelector } from 'react-redux';

import { TodoItem, TodoType } from '@advitam/api/models/Deal/TodoItem';
import { assert } from '@advitam/support';

import {
  bookSuppliers,
  preBookSuppliers,
} from 'containers/Deal/BookingSection/actions';
import { makeSelectDeal } from 'containers/Deal/selectors.typed';
import { ButtonV2 as Button } from 'components/Button';

import TaskRow from './Row';
import Label from './Label';

interface SupplierBookingProps {
  item: TodoItem;
}

export default function SupplierBookingTask({
  item,
}: SupplierBookingProps): JSX.Element {
  const dispatch = useDispatch();
  const deal = useSelector(makeSelectDeal());
  assert(deal !== null);

  const book = (): void => {
    if (item.todo_type === TodoType.BOOK_SUPPLIERS) {
      dispatch(bookSuppliers(deal.uuid));
    } else {
      dispatch(preBookSuppliers(deal.uuid));
    }
  };

  return (
    <TaskRow item={item}>
      <Button className={!item.done ? 'button--nothing' : ''} onClick={book}>
        <Label title={item.title} />
      </Button>
    </TaskRow>
  );
}
