import type { ReactNode } from 'react';

import { Frame, Heading } from '@advitam/ui';

import style from './Layout.module.scss';

interface LayoutProps {
  title: ReactNode;
  children: ReactNode | ReactNode[];
}

export default function Layout({ title, children }: LayoutProps): JSX.Element {
  return (
    <main className={style.main}>
      <Frame darker className={`col-5 ${style.content}`}>
        <Heading small>
          <b>{title}</b>
        </Heading>
        {children}
      </Frame>
    </main>
  );
}
