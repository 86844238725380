import Api from '@advitam/api'
import { SubmitButton } from '@advitam/ui'
import GoogleIcon from '@advitam/ui/images/icons/google.svg'

import { useOmniauthError } from './useOmniauthError'
import withOmniauth from './withOmniauth'
import style from './OmniAuth.module.scss'

export default function OmniAuth(): JSX.Element {
  const origin = new URL(window.location.href)
  origin.searchParams.set('resource_class', 'BusinessUser')

  return (
    <div className={style.omni_auth}>
      <form action={Api.V1.absolute(Api.Omniauth.googleOauth2)} method="POST">
        <input type="hidden" name="namespace_name" value="api_v1_pro" />
        <input type="hidden" name="resource_class" value="BusinessUser" />
        <input type="hidden" name="origin" value={origin.toString()} />
        <SubmitButton outline reverse icon={<GoogleIcon />} text="Google" />
      </form>
    </div>
  )
}

export { useOmniauthError, withOmniauth }
