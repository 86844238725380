export function getObject<T>(params: ReadonlyArray<keyof T>): T | null {
  const searchParams = new URLSearchParams(window.location.search)
  if (!params.every(param => searchParams.has(param.toString()))) {
    return null
  }

  return params.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: searchParams.get(curr.toString()),
    }),
    {} as T,
  )
}
