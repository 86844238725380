import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { TodoType } from '@advitam/api/models/Deal/TodoItem';
import { ConfirmationModal, Text } from '@advitam/ui';
import { withSlice } from '@advitam/react';
import { assert } from '@advitam/support';

import {
  bookSuppliers,
  preBookSuppliers,
} from 'containers/Deal/BookingSection/actions';
import { makeSelectDeal } from 'containers/Deal/selectors.typed';
import actionsMessages from 'messages/actions';

import messages from './messages';
import { makeSelectBookingItem } from './selectors';
import slice, { setBookingItem } from './slice';
import style from './SupplierBookingModal.module.scss';

function SupplierBookingModal(): JSX.Element {
  const dispatch = useDispatch();

  const bookingItem = useSelector(makeSelectBookingItem());
  const deal = useSelector(makeSelectDeal());
  assert(deal !== null);

  const descriptionMessage =
    bookingItem?.todo_type === TodoType.BOOK_SUPPLIERS
      ? messages.confirmBooking
      : messages.confirmPreBooking;

  const onClose = (): void => {
    dispatch(setBookingItem(null));
  };

  const onConfirm = (): void => {
    assert(bookingItem !== null);

    if (bookingItem.todo_type === TodoType.BOOK_SUPPLIERS) {
      dispatch(bookSuppliers(deal.uuid));
    } else {
      dispatch(preBookSuppliers(deal.uuid));
    }

    onClose();
  };

  return (
    <ConfirmationModal
      isOpen={Boolean(bookingItem)}
      className={`new-design col-4 ${style.modal}`}
      cancel={<FormattedMessage id={actionsMessages.cancel.id} />}
      onCancel={onClose}
      confirm={<FormattedMessage id={actionsMessages.confirm.id} />}
      onConfirm={onConfirm}
      title={<FormattedMessage id={messages.title.id} />}
      text={
        <Text tagName="p">
          <FormattedMessage id={descriptionMessage.id} />
        </Text>
      }
    />
  );
}

export default withSlice(slice)(SupplierBookingModal);
