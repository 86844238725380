import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  functioning: {
    id: 'app.containers.Supplier.Warehouse.sections.Miscellaneous.functioning',
  },
  prebooking: {
    id: 'app.containers.Supplier.Warehouse.sections.Miscellaneous.prebooking',
  },
  prebookingTooltip: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.prebookingTooltip',
  },
  prebookingContactMedia: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.prebookingContactMedia',
  },
  prebookingContactMediaTooltip: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.prebookingContactMediaTooltip',
  },
  booking: {
    id: 'app.containers.Supplier.Warehouse.sections.Miscellaneous.booking',
  },
  bookingTooltip: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.bookingTooltip',
  },
  bookingContactMedia: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.bookingContactMedia',
  },
  bookingContactMediaTooltip: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.bookingContactMediaTooltip',
  },
  emailMedia: {
    id: 'app.containers.Supplier.Warehouse.sections.Miscellaneous.emailMedia',
  },
  smsMedia: {
    id: 'app.containers.Supplier.Warehouse.sections.Miscellaneous.smsMedia',
  },
  faxMedia: {
    id: 'app.containers.Supplier.Warehouse.sections.Miscellaneous.faxMedia',
  },
  coffinDelivery: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.coffinDelivery',
  },
  coffinDeliveryTooltip: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.coffinDeliveryTooltip',
  },
  coffinDeliveryYes: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.coffinDeliveryYes',
  },
  coffinDeliveryNo: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.coffinDeliveryNo',
  },
  deliveryFees: {
    id: 'app.containers.Supplier.Warehouse.sections.Miscellaneous.deliveryFees',
  },
  deliveryFeesTooltip: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.deliveryFeesTooltip',
  },
  repatriationByPlane: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.repatriationByPlane',
  },
  grade: {
    id: 'app.containers.Supplier.Warehouse.sections.Miscellaneous.grade',
  },
  gradeTooltip: {
    id: 'app.containers.Supplier.Warehouse.sections.Miscellaneous.gradeTooltip',
  },

  repatriation: {
    id: 'app.containers.Supplier.Warehouse.sections.Miscellaneous.repatriation',
  },
  priceRequest: {
    id: 'app.containers.Supplier.Warehouse.sections.Miscellaneous.priceRequest',
  },
  priceRequestPreferedContactMedia: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.priceRequestPreferedContactMedia',
  },
  priceRequestPreferedContactMediaTooltip: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.priceRequestPreferedContactMediaTooltip',
  },
  departureAirport: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.departureAirport',
  },
  departureAirportTooltip: {
    id:
      'app.containers.Supplier.Warehouse.sections.Miscellaneous.departureAirportTooltip',
  },
});
